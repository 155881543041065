import React, { useEffect, useState } from "react";
import StepWizard from "react-step-wizard";
import DatosEducacion from "../pasos/DatosEducacion";
import DatosEmpresa from "../pasos/DatosEmpresa";
import DatosExperienciaLaboral from "../pasos/DatosExperienciaLaboral";
import DatosPersonales from "../pasos/DatosPersonales";
import DatosReferencias from "../pasos/DatosReferencias";

export default function FormularioPostulante({
  setModal,
  setMostrarModal,
  uuIdProceso,
  preguntas,
  preguntasCargo,
  preguntasEmpresa,
  setIniciaVideo,
  setIniciaFormulario,
  setPostulante,
}) {
  const [formulario, setFormulario] = useState({});
  const [stepEnviaFormulariom, setStepEnviaFormulario] = useState();
  const [file, setFile] = useState();

  const onAddAtribute = (llave, valor) => {
    if (llave === "licencias") {
      formulario[llave] = [];
      valor.forEach((element) => {
        formulario[llave].push({ idLicencia: element });
      });
    } else {
      if (llave === "rut") {
        valor = valor.replace(/[^0-9kK]/g, "");
        if (valor.length > 1) {
          valor =
            valor.substring(0, valor.length - 1) +
            "-" +
            valor.substring(valor.length - 1, valor.length);
        }
      }
      if (llave === "expectativaRenta") {
        valor = valor.replace(/[^0-9]/g, "");
      }
      formulario[llave] = valor;
    }
  };

  const validarPaso = () => {};

  const getFormulario = () => {
    return formulario;
  };

  const handletFormulario = (postulante) => {
    setFormulario(
      postulante === null || postulante === undefined ? formulario : postulante
    );
  };

  const uploadFile = (file) => {
    setFile(file);
  };

  const enviarFormulario = async () => {
    setModal({
      closeModal: false,
      subtitulo: "Ingreso Proceso Postulante",
      texto: "Enviando formulario...",
      spinner: true,
    });
    onAddAtribute("uuIdProceso", uuIdProceso);
    let postulante = new FormData();

    if (
      formulario["expectativaRenta"] !== undefined &&
      formulario["expectativaRenta"].trim().length > 0
    ) {
      formulario["expectativaRenta"] = formulario["expectativaRenta"].replace(
        /[^0-9]/g,
        ""
      );
    }
    formulario["archivos"] = [];
    for (const index in file) {
      if (file[index].file !== undefined && file[index].file !== null) {
        postulante.append("file", file[index].file);
      } else {
        formulario["archivos"].push({
          idDocumento: file[index].idDocumento,
          name: file[index].name,
        });
      }
    }
    postulante.append(
      "postulante",
      new Blob([JSON.stringify(formulario)], { type: "application/json" })
    );

    const requestOptions = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      body: postulante,
      redirect: "follow",
    };

    try {
      const fetchResponse = await fetch(
        process.env.REACT_APP_URL_BASE_API_POSTULANTE + "postulante",
        requestOptions
      );
      const data = await fetchResponse.json();
      setTimeout(() => {
        if (data.codeResponse === 200) {
          let existeVideo = preguntas.filter(
            (pregunta) => pregunta.idTipoFormulario === 5
          );
          if (
            existeVideo.length > 0 &&
            existeVideo[0].preguntas.filter((p) => p.activo === 1).length > 0
          ) {
            let texto = "";
            if (
              formulario.fechaPostulacion !== undefined &&
              formulario.fechaPostulacion !== null
            ) {
              texto = "Formulario postulante actualizado";
            } else {
              texto = "Formulario postulante enviado";
            }
            setModal({
              closeModal: true,
              subtitulo: "Proceso Postulante",
              texto: texto,
            });
            setPostulante(data.postulante);
            setIniciaVideo(true);
            setIniciaFormulario(false);
          } else {
            setModal({
              closeModal: false,
              subtitulo: "Proceso Postulante",
              texto: "Gracias por participar en el proceso",
            });
          }
        } else {
          setModal({
            closeModal: true,
            subtitulo: "Proceso Postulante",
            texto: "Se ha producido un error favor de intentar mas tarde.",
          });
        }
      }, 2000);
    } catch (e) {
      return e;
    }
    // }
  };

  const getTotalPreguntasGrupo = (grupo) => {
    let tipoPregunta = preguntas.filter((t) => {
      return t.idTipoFormulario === grupo;
    });
    if (tipoPregunta.length > 0) {
      let activos = tipoPregunta[0].preguntas.filter((p) => p.activo === 1);
      return activos.length;
    }
    return 0;
  };

  useEffect(() => {
    let pos = 0;
    if (getTotalPreguntasGrupo(1) > 0) {
      pos = 1;
    }
    if (getTotalPreguntasGrupo(2) > 0) {
      pos = 2;
    }
    if (getTotalPreguntasGrupo(3) > 0) {
      pos = 3;
    }
    if (getTotalPreguntasGrupo(4) > 0) {
      pos = 4;
    }
    if (
      (preguntasCargo.length > 0 &&
        preguntasCargo.filter((p) => p.activo === true).length > 0) ||
      (preguntasEmpresa.length > 0 &&
        preguntasEmpresa.filter((p) => p.activo === true).length > 0)
    ) {
      pos = 5;
    }
    setStepEnviaFormulario(pos);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StepWizard isHashEnabled>
      {preguntas.length > 0 &&
        preguntas.map((tipoPregunta, index) => {
          let hashKey = tipoPregunta.descripcion.replace(/ /g, "_");
          let preguntasFormulario;
          switch (tipoPregunta.idTipoFormulario) {
            case 1:
              preguntasFormulario = (
                <DatosPersonales
                  key={`datos-personales-${index + 1}`}
                  preguntas={tipoPregunta.preguntas.filter(
                    (p) => p.activo === 1
                  )}
                  hashKey={hashKey}
                  onDataChange={onAddAtribute}
                  validador={validarPaso}
                  formulario={getFormulario}
                  setFormulario={handletFormulario}
                  //actualizar={actualizar}
                  idProceso={uuIdProceso}
                  setModal={setModal}
                  setMostrarModal={setMostrarModal}
                  setPostulante={setPostulante}
                  enviarFormulario={
                    stepEnviaFormulariom === 1 ? enviarFormulario : undefined
                  }
                />
              );
              break;
            case 2:
              preguntasFormulario = (
                <DatosEducacion
                  key={`datos-educacion-${index + 1}`}
                  preguntas={tipoPregunta.preguntas.filter(
                    (p) => p.activo === 1
                  )}
                  hashKey={hashKey}
                  onDataChange={onAddAtribute}
                  formulario={getFormulario}
                  setModal={setModal}
                  setMostrarModal={setMostrarModal}
                  enviarFormulario={
                    stepEnviaFormulariom === 2 ? enviarFormulario : undefined
                  }
                />
              );
              break;
            case 3:
              preguntasFormulario = (
                <DatosExperienciaLaboral
                  key={`datos-experianci-laboral-${index + 1}`}
                  preguntas={tipoPregunta.preguntas.filter(
                    (p) => p.activo === 1
                  )}
                  hashKey={hashKey}
                  onDataChange={onAddAtribute}
                  formulario={getFormulario}
                  uploadFile={uploadFile}
                  file={file}
                  archivorequerido={true}
                  setModal={setModal}
                  setMostrarModal={setMostrarModal}
                  enviarFormulario={
                    stepEnviaFormulariom === 3 ? enviarFormulario : undefined
                  }
                />
              );
              break;
            case 4:
              preguntasFormulario = (
                <DatosReferencias
                  key={`datos-referencia-${index + 1}`}
                  preguntas={tipoPregunta.preguntas.filter(
                    (p) => p.activo === 1
                  )}
                  hashKey={hashKey}
                  onDataChange={onAddAtribute}
                  formulario={getFormulario}
                  setModal={setModal}
                  setMostrarModal={setMostrarModal}
                  enviarFormulario={
                    stepEnviaFormulariom === 4 ? enviarFormulario : undefined
                  }
                  value={getFormulario()["referencias"]}
                />
              );
              break;
            default:
              break;
          }
          let activos = tipoPregunta.preguntas.filter((p) => p.activo === 1);
          if (tipoPregunta.idTipoFormulario > 1 && activos.length === 0) {
            preguntasFormulario = undefined;
          }
          return preguntasFormulario;
        })}
      {((preguntasCargo.length > 0 &&
        preguntasCargo.filter((p) => p.activo === true).length > 0) ||
        (preguntasEmpresa.length > 0 &&
          preguntasEmpresa.filter((p) => p.activo === true).length > 0)) && (
        <DatosEmpresa
          hashKey={"Preguntas_Postulacion"}
          preguntasCargo={preguntasCargo}
          preguntasEmpresa={preguntasEmpresa}
          onAddAtribute={onAddAtribute}
          getFormulario={getFormulario}
          enviarFormulario={enviarFormulario}
        />
      )}
    </StepWizard>
  );
}
