import React, { useEffect } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { FormHelperText } from "@material-ui/core";

export default function CustomRadioInput({
  valueIn,
  url,
  disabled = false,
  requiered = false,
  validar,
  formulario,
  index,
  className,
  label,
  name,
  validarFormulario,
  onDataChange,
  style = {},
  listIn = [],
}) {
  const [value, setValue] = React.useState("");
  const [list, setList] = React.useState([]);
  const [tieneError, setTieneError] = React.useState(false);
  const [textoError, setTextoError] = React.useState("");
  const [actualizar, setActualizar] = React.useState(false);
  const [clase, setClase] = React.useState("");
  const [disabledAux, setDisabledAux] = React.useState();
  const [requieredAux, setRequieredAux] = React.useState();

  const getData = () =>
    fetch(process.env.REACT_APP_URL_COMBOBOX + url).then((res) => res.json());

  useEffect(() => {
    if (valueIn !== undefined && value !== valueIn) {
      handleChange({ target: { value: valueIn } });
      setActualizar(!actualizar);
    }
  }, [valueIn]);

  useEffect(() => {
    if (
      validar &&
      requieredAux &&
      (value === null || value.length === 0) &&
      !disabledAux
    ) {
      setTieneError(true);
      setClase("error");
      setTextoError("El campo es requerido");
      if (validarFormulario !== undefined) {
        validarFormulario(name, true);
      }
    }
  }, [validar, disabled, formulario, actualizar]);

  useEffect(() => {
    if (url !== undefined) {
      getData().then((data) => setList(data.listaItems));
    } else {
      setList(listIn);
    }
  }, [url]);

  useEffect(() => {
    if (disabled === true || disabled === "true") {
      setTieneError(false);
      setClase("");
      setTextoError("");
      setDisabledAux(true);
    } else {
      setDisabledAux(false);
    }
  }, [disabled]);

  useEffect(() => {
    if (requiered === true || requiered === "true") {
      setRequieredAux(true);
    } else {
      setRequieredAux(false);
    }
  }, [requiered]);

  useEffect(() => {}, [clase]);

  const handleChange = (event) => {
    setTieneError(false);
    setClase("sucess");
    if (validarFormulario !== undefined) {
      validarFormulario(name, false);
    }
    setValue(event.target.value + "");
    if (index !== undefined) {
      onDataChange(index, name, event.target.value);
    } else {
      onDataChange(name, event.target.value);
    }
  };

  return (
    <FormControl
      component="fieldset"
      fullWidth={true}
      className={`${className} 
        ${disabled !== undefined && disabled ? "radio-disabled" : ""}`}
      style={style === undefined ? {} : style}
    >
      {tieneError ? (
        <FormLabel error component="legend" className={"radio-legend"}>
          <span>{label}</span>
        </FormLabel>
      ) : (
        <FormLabel component="legend" className={"radio-legend"}>
          <span>{label}</span>
        </FormLabel>
      )}
      <RadioGroup
        className={`radio ${clase}`}
        aria-label="gender"
        name="gender1"
        value={value}
        onChange={handleChange}
      >
        {list !== null &&
          list.map((item, index) => {
            return (
              <FormControlLabel
                disabled={disabled !== undefined && disabled}
                key={"radio-" + index}
                value={item.value}
                control={<Radio checked={item.value === value} />}
                label={item.label}
              />
            );
          })}
      </RadioGroup>
      {tieneError && (
        <FormHelperText className="Mui-error">{textoError}</FormHelperText>
      )}
    </FormControl>
  );
}
