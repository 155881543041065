import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core";

export default function DescripcionCargo({
  cargo,
  proceso,
  setMostrarDescripcion,
}) {
  useEffect(() => {}, [cargo, proceso]);

  return (
    <Card
      style={{
        padding: 10,
      }}
    >
      <CardContent
        style={{
          padding: 0,
        }}
      >
        <CardHeader title="Cargo" />
        <Typography
          variant="body1"
          gutterBottom
          style={{
            border: "1px solid #919191",
            padding: 10,
            borderRadius: 5,
          }}
        >
          {cargo.nombre}
        </Typography>
      </CardContent>
      <CardContent style={{ padding: 0 }}>
        <CardHeader title="Region del Proceso" />
        <Typography
          variant="body1"
          gutterBottom
          style={{
            border: "1px solid #919191",
            padding: 10,
            borderRadius: 5,
          }}
        >
          {proceso.region.nombre}
        </Typography>
      </CardContent>
      <CardContent style={{ padding: 0 }}>
        <CardHeader title="Publicación del Proceso" />
        <Typography
          variant="body1"
          gutterBottom
          style={{
            border: "1px solid #919191",
            padding: 10,
            borderRadius: 5,
          }}
        >
          {proceso.descripcionProceso}
        </Typography>
      </CardContent>
      <CardContent style={{ padding: 0 }}>
        <CardHeader title="Fecha Inicio Proceso" />
        <Typography
          variant="body1"
          gutterBottom
          style={{
            border: "1px solid #919191",
            padding: 10,
            borderRadius: 5,
          }}
        >
          {proceso.fechaInicioProceso}
        </Typography>
      </CardContent>
      <Button
        variant="contained"
        onClick={() => setMostrarDescripcion(false)}
        style={{
          height: 50,
          marginRight: 10,
          backgroundColor: "#e27e07",
          color: "white",
        }}
      >
        {"Postular aquí"}
      </Button>
    </Card>
  );
}
