import {
  AppBar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CutomModal from "../pasos/CutomModal";
import CountDownTimer from "../utiles/CountDownTimer";
import PreguntaPRP from "./PreguntaPRP";
import preguntas from "./PreguntasPRP";
import "./PRP.css";

const URL_POSTULANTE = process.env.REACT_APP_URL_BASE_API_POSTULANTE;

export default function PRP() {
  const [uuIdTest] = React.useState(
    new URLSearchParams(window.location.search).get("uuIdTest")
  );
  const [aceptaCondiciones, setAceptaCondiciones] = useState(false);
  const [mostrarModal, setMostrarModal] = React.useState(false);
  const [tokenValido, setTokenValido] = React.useState(false);
  const [datosModal, setDatosModal] = React.useState({
    closeModal: false,
    subtitulo: null,
    texto: null,
  });

  const validaToken = async () => {
    try {
      const fetchResponse = await fetch(
        URL_POSTULANTE + "test/validatoken/" + uuIdTest
      );
      const data = await fetchResponse.json();
      if (data.codeResponse !== 200) {
        setDatosModal({
          closeModal: false,
          subtitulo: "Test PRP",
          texto: "El id de test ingresado no es valido",
        });
        setMostrarModal(true);
      } else {
        setTokenValido(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const iniciarTest = async () => {
    try {
      const fetchResponse = await fetch(
        URL_POSTULANTE + "test/iniciar/" + uuIdTest
      );
      const data = await fetchResponse.json();
      if (data.codeResponse !== 200) {
        setDatosModal({
          closeModal: true,
          subtitulo: "Test PRP",
          texto: "Se produjo un error no se puede iniciar el TEST.",
        });
        setMostrarModal(true);
      } else {
        setAceptaCondiciones(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const finalizarTest = async (acaboElTiempo = false) => {
    try {
      const fetchResponse = await fetch(
        URL_POSTULANTE + "test/finalizar/" + uuIdTest
      );
      const data = await fetchResponse.json();
      if (data.codeResponse !== 200) {
        setDatosModal({
          closeModal: true,
          subtitulo: "Test PRP",
          texto: "Se produjo un error no se puede finalizar el TEST.",
        });
      } else {
        let mensaje = "Gracias por participar en el proceso.";
        if (acaboElTiempo) {
          mensaje =
            "Se ha acabado el tiempo asignado para responder el TEST. " +
            mensaje;
        }
        setDatosModal({
          closeModal: false,
          subtitulo: "Test PRP",
          texto: mensaje,
        });
      }
      setMostrarModal(true);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (uuIdTest == null) {
      setDatosModal({
        closeModal: false,
        subtitulo: "Test PRP",
        texto: "Debe ingresar un ID de Test para continuar",
      });
      setMostrarModal(true);
    } else {
      if (!tokenValido) {
        validaToken();
      }
    }
  }, [uuIdTest, tokenValido]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={{ flexGrow: 1 }}>
      {!aceptaCondiciones && (
        <Card style={{ margin: 30 }}>
          <CardContent>
            <Typography
              variant="h3"
              gutterBottom
              component="div"
              style={{
                textAlign: "center",
                textDecoration: "underline",
                marginBlock: 30,
              }}
            >
              INSTRUCCIONES
            </Typography>
            <Typography
              variant="body1"
              style={{
                textAlign: "center",
                marginBlock: 30,
              }}
              gutterBottom
            >
              LEA ESTA PÁGINA CUIDADOSAMENTE Y SIGA LAS INSTRUCCIONES
              EXACTAMENTE.
            </Typography>
            <br />
            <Typography variant="body1" gutterBottom>
              Esta es una prueba que evalúa sus actitudes frente a algunas
              situaciones que se dan en el ámbito laboral. La prueba se compone
              de afirmaciones, a las que debe responder su nivel de acuerdo,
              marcando una de las alternativas entre: totalmente de acuerdo – de
              acuerdo – indeciso – en desacuerdo – totalmente en desacuerdo.
              Debe marcar <strong>una sola</strong> de las alternativas en cada
              pregunta.
            </Typography>
            <br />
            <Typography variant="body1" gutterBottom>
              Esta prueba se compone de 30 preguntas. Una vez abierto el link
              del test que se le ha enviado usted tendrá exactamente{" "}
              <strong>12 minutos</strong> para responder a tantas preguntas como
              pueda. No se detenga demasiado en cada pregunta, porque podría
              faltarle tiempo para terminar la prueba y esto va a influir en su
              resultado.
            </Typography>
          </CardContent>
          <CardActions style={{ justifyContent: "end" }}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#f47c06", color: "#ffffff" }}
              onClick={() => iniciarTest()}
            >
              Iniciar TEST
            </Button>
          </CardActions>
        </Card>
      )}
      {aceptaCondiciones && (
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="fixed" style={{ backgroundColor: "#0a5dc1" }}>
            <Toolbar style={{ justifyContent: "space-between" }}>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Test PRP
              </Typography>
              <CountDownTimer
                onFinish={() => finalizarTest(true)}
                hoursMinSecs={{ hours: 0, minutes: 12, seconds: 0 }}
              />
            </Toolbar>
            <Toolbar
              style={{
                backgroundColor: "#f47c06",
                paddingBlock: 10,
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#0a5dc1",
                    padding: 5,
                    marginRight: 5,
                    width: 70,
                    height: 70,
                    borderRadius: 50,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src="/Logos_v04_LOGO.png" width={65} alt={""} />
                </div>
                <div>
                  <Typography
                    variant="h4"
                    component="div"
                    style={{ flexGrow: 1 }}
                  >
                    Escala PRP
                  </Typography>
                  <Typography
                    variant="h6"
                    component="div"
                    style={{ flexGrow: 1 }}
                  >
                    (Prevención de Riesgos Profesionales)
                  </Typography>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h2"
                  component="div"
                  style={{ flexGrow: 1, fontWeight: "bold" }}
                >
                  A
                </Typography>
                <Typography
                  variant="h7"
                  component="div"
                  style={{ flexGrow: 1, fontWeight: "bold" }}
                >
                  Forma
                </Typography>
              </div>
            </Toolbar>
          </AppBar>
          <div
            style={{ marginTop: 175, backgroundColor: "#fff" }}
            className="contendor-preguntas-prp"
          >
            <Grid aria-label="sticky table" stickyHeader>
              <Grid>
                <Grid container className="pregunta-header">
                  {/*<Grid
                    item
                    style={{
                      backgroundColor: "#dedede",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    xs={1}
                  >
                    N°
                  </Grid>*/}
                  <Grid
                    item
                    style={{
                      backgroundColor: "#dedede",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    xs={7}
                  >
                    AFIRMACIÓN
                  </Grid>
                  <Grid
                    item
                    style={{
                      backgroundColor: "#dedede",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    xs={1}
                  >
                    TOTALMENTE DE ACUERDO
                  </Grid>
                  <Grid
                    item
                    style={{
                      backgroundColor: "#dedede",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    xs={1}
                  >
                    DE ACUERDO
                  </Grid>
                  <Grid
                    item
                    style={{
                      backgroundColor: "#dedede",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    xs={1}
                  >
                    INDECISO
                  </Grid>
                  <Grid
                    item
                    style={{
                      backgroundColor: "#dedede",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    xs={1}
                  >
                    EN DESACUERDO
                  </Grid>
                  <Grid
                    item
                    style={{
                      backgroundColor: "#dedede",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    xs={1}
                  >
                    TOTALMENTE EN DESACUERDO
                  </Grid>
                </Grid>
              </Grid>
              <Grid>
                {preguntas !== undefined &&
                  preguntas.map((pregunta, index) => {
                    pregunta["id"] = index + 1;
                    return (
                      <PreguntaPRP
                        index={index + 1}
                        key={`pregunta_PRP_${index}`}
                        pregunta={pregunta}
                        uuidTest={uuIdTest}
                      />
                    );
                  })}
              </Grid>
            </Grid>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: 20,
              height: 40,
              backgroundColor: "#0a5dc1",
            }}
          >
            <div style={{ textAlign: "end", width: "100%" }}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#f47c06",
                  color: "#ffffff",
                  width: "100%",
                }}
                onClick={() => finalizarTest()}
              >
                Finalizar TEST PRP
              </Button>
            </div>
          </div>
        </Box>
      )}
      <CutomModal
        setMostrarModal={setMostrarModal}
        open={mostrarModal}
        closeModal={datosModal.closeModal}
        subtitulo={datosModal.subtitulo}
        texto={datosModal.texto}
      />
    </Box>
  );
}
