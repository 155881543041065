import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MaskedInput from "react-text-mask";
import Validador from "../utiles/Validador";

const useStyles = makeStyles((theme) => ({
  formInput: {
    marginBottom: 20,
  },
}));

export default function CustomTextInput(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState("");
  const [tieneError, setTieneError] = React.useState(false);
  const [maskText] = React.useState(props.mask);
  const [mask, setMask] = React.useState();
  const [textoError, setTextoError] = React.useState("");
  const [datoValido, setDatoValido] = React.useState("");
  const validador = Validador();
  const [actualizar, setActualizar] = React.useState(false);

  function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={maskText}
        placeholderChar={"\u2000"}
      />
    );
  }

  TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
  };

  useEffect(() => {
    if (props.disabled !== undefined && props.disabled) {
      setTieneError(false);
      setTextoError("");
    } else {
      if (
        props.value !== null &&
        props.value !== undefined &&
        props.value.length > 0
      ) {
        let valor = "";
        if (props.type === "date") {
          let fecha = props.value.split("/");
          if (fecha.length === 3) {
            valor = fecha[2] + "-" + fecha[1] + "-" + fecha[0];
          }
        } else {
          valor = formatoValue(props.value);
        }
        if (valor.length > 0 && valor !== value) {
          onChangeValue(valor);
          setActualizar(true);
        }
      }
      if (props.validar) {
        validarInput();
      } else {
        setTieneError(false);
        setTextoError("");
      }
    }
    if (actualizar) {
      validarInput();
      setActualizar(false);
    }
    if (props.mask !== undefined) {
      setMask({
        inputComponent: TextMaskCustom,
      });
    }
  }, [
    props.validar,
    props.formulario,
    actualizar,
    props.disabled,
    props.recargar,
    props.value,
  ]);

  const onChangeValue = (text) => {
    let largoCampo = props.maxLength !== undefined ? props.maxLength : 1000;

    let valueFormat;
    switch (props.valueType) {
      case "rut":
        valueFormat = text.replace(/[^0-9kK]/g, "");
        break;
      case "telefono":
      case "nota":
      case "periodo":
      case "moneda":
        valueFormat = text.replace(/[^0-9]/g, "");
        break;
      default:
        valueFormat = text;
        break;
    }
    if (valueFormat.length > largoCampo) {
      agregaError(
        `El campo debe tener un largo menor o igual a ${largoCampo} caracteres`
      );
      return;
    }
    setTieneError(false);
    if (props.validarFormulario !== undefined) {
      props.validarFormulario(props.name, false, props.index);
      if (props.index !== undefined) {
        if (props.nombreLista !== undefined) {
          props.validarFormulario(
            props.name,
            false,
            props.nombreLista,
            props.index
          );
        } else {
          props.validarFormulario(props.name, false, props.index);
        }
      }
    }
    setValue(text);
    if (props.index !== undefined) {
      if (props.nombreLista !== undefined)
        props.onDataChange(props.nombreLista, props.index, props.name, text);
      else props.onDataChange(props.index, props.name, text);
    } else props.onDataChange(props.name, text);
  };

  const validaLargo = (text, largo) => {
    if (text.length < largo) return true;
    return false;
  };

  const soloNumeros = (charCode) => {
    if (charCode >= 48 && charCode <= 57) {
      return true;
    }
    return false;
  };

  const validarInput = () => {
    var date = new Date();
    setDatoValido("");
    if (value !== undefined && value !== null && value.length !== 0) {
      switch (props.valueType) {
        case "rut":
          if (validador.rut(value)) {
            setDatoValido("success");
          } else {
            agregaError("RUT ingresado no valido");
          }
          break;
        case "nota":
          if (validador.nota(value)) {
            setDatoValido("success");
          } else {
            agregaError("Nota debe estar entre 2.0 y 7.0");
          }
          break;
        case "anio":
          if (validador.anio(value, date)) {
            setDatoValido("success");
          } else {
            agregaError("Año debe estar entre 1900 y " + date.getFullYear());
          }
          break;
        case "periodo":
          let periodo = value.replace(/[^0-9kK]/g, "");
          let respuesta = validador.periodo(periodo, date);
          if (respuesta.valido) {
            setDatoValido("success");
          } else {
            let mensajeError = "";
            if (respuesta.anio)
              mensajeError +=
                "Año debe estar entre 1900 y " + date.getFullYear();
            if (respuesta.anio && respuesta.mes) mensajeError += " - ";
            if (respuesta.mes) mensajeError += "Mes debe estar entre 01 y 12";
            agregaError(mensajeError);
          }
          break;
        case "email":
          if (validador.email(value)) {
            setDatoValido("success");
          } else {
            agregaError("Email con formato incorrecto, Ej: nombre@domio.cl");
          }
          break;
        case "telefono":
          let numeros = value.replace(/[^0-9kK]/g, "");
          if (numeros.length === 9) {
            setDatoValido("success");
          } else {
            agregaError("El largo del numero ingresado deber igual a 9");
          }
          break;

        default:
          setDatoValido("success");
          setTieneError(false);
          setTextoError("");
          break;
      }
    } else {
      let requiered =
        props.requiered !== undefined &&
        (props.requiered === true || props.requiered === "true");
      if (props.validar && requiered && value.length === 0) {
        agregaError("El campo es requerido.");
      } else {
        setTieneError(false);
        setTextoError("");
      }
    }
  };

  const agregaError = (glosa) => {
    setTieneError(true);
    setTextoError(glosa);
    if (props.validarFormulario !== undefined) {
      props.validarFormulario(props.name, true, props.index);
    }
  };

  const handleKeyPress = (e, typo) => {
    let largoCampo = props.maxLength !== undefined ? props.maxLength : 1000;
    if (!validaLargo(e.target.value, largoCampo)) {
      agregaError(
        `El campo debe tener un largo menor o igual a ${largoCampo} caracteres`
      );
      e.preventDefault();
    }
    const charCode = e.which ? e.which : e.keyCode;
    switch (typo) {
      case "rut":
        if (!soloNumeros(charCode) && charCode !== 75 && charCode !== 107) {
          e.preventDefault();
        }
        break;
      case "telefono":
        if (!soloNumeros(charCode)) {
          e.preventDefault();
        }
        break;
      case "nota":
        if (!soloNumeros(charCode) && charCode !== 46) {
          e.preventDefault();
        }
        break;
      case "anio":
        if (!soloNumeros(charCode)) {
          e.preventDefault();
        }
        break;
      case "periodo":
        if (!soloNumeros(charCode) && charCode !== 45) {
          e.preventDefault();
        }
        break;
      case "moneda":
        if (!soloNumeros(charCode)) {
          e.preventDefault();
        }
        break;
      case "nota":
        if (!soloNumeros(charCode)) {
          e.preventDefault();
        }
        break;
      default:
        break;
    }
  };

  const formatoValue = (value) => {
    if (value === null) {
      value = "";
    }
    let valueFormat;
    switch (props.valueType) {
      case "rut":
        valueFormat = value.replace(/[^0-9kK]/g, "");
        valueFormat = formato_rut(valueFormat);
        break;
      case "telefono":
        valueFormat = value.replace(/[^0-9]/g, "");
        valueFormat = formato_telefono(valueFormat);
        break;
      case "nota":
        valueFormat = value.replace(/[^0-9]/g, "");
        valueFormat = formato_nota(valueFormat);
        break;
      case "periodo":
        valueFormat = value.replace(/[^0-9]/g, "");
        valueFormat = formato_periodo(valueFormat);
        break;
      case "moneda":
        valueFormat = value.replace(/[^0-9]/g, "");
        valueFormat = formato_moneda(valueFormat);
        break;
      case "nombre":
        valueFormat = formato_nombre(value);
        break;
      default:
        valueFormat = value;
        break;
    }
    return valueFormat;
  };

  const onBlur = () => {
    validarInput();
    let valueFormat = formatoValue(value);
    setValue(valueFormat);
    if (props.onBlurChange !== undefined) {
      props.onBlurChange(valueFormat);
    }
  };

  const onFocus = () => {
    setTieneError(false);
    switch (props.valueType) {
      case "rut":
        setValue(value.replace(/[^0-9kK]/g, ""));
        break;
      case "telefono":
      case "periodo":
        setValue(value.replace(/[^0-9]/g, ""));
        break;
      case "nota":
        break;
      case "moneda":
        setValue(value.replace(/[^0-9]/g, ""));
        break;
      default:
        break;
    }
  };

  const formato_nombre = (nombre) => {
    var letra = nombre.substr(0, 1);
    var resto = nombre.substr(1);
    return letra.toUpperCase() + resto;
  };

  const formato_periodo = (periodo) => {
    var anio = periodo.substr(2, periodo.length - 1);
    var mes = periodo.substr(0, periodo.length - 4);
    return mes + "-" + anio;
  };

  const formato_moneda = (moneda) => {
    let aux = "";
    let cont = 0;
    let decimales = "";
    if (moneda.indexOf(".") > 0) {
      decimales = moneda.substring(moneda.indexOf(".") + 1);
    }
    moneda = parseInt(moneda) + "";
    for (let i = moneda.length - 1; i >= 0; i--) {
      if (cont === 3) {
        aux = "." + aux;
        cont = 0;
      }
      aux = moneda.charAt(i) + aux;
      cont++;
    }
    moneda = aux;
    if (decimales.length > 0) {
      moneda = moneda + "," + decimales;
    }
    return "$ " + moneda;
  };

  const formato_nota = (nota) => {
    var sRut1 = nota; //contador de para saber cuando insertar el . o la -
    var nPos = 0; //Guarda el rut invertido con los puntos y el guión agregado
    var sInvertido = ""; //Guarda el resultado final del rut como debe ser
    var sRut = "";
    for (var i = sRut1.length - 1; i >= 0; i--) {
      sInvertido += sRut1.charAt(i);
      nPos++;
      if (nPos === 1 && i > 0) {
        sInvertido += ".";
        nPos = 0;
      }
    }
    for (var j = sInvertido.length - 1; j >= 0; j--) {
      if (sInvertido.charAt(sInvertido.length - 1) !== ".")
        sRut += sInvertido.charAt(j);
      else if (j !== sInvertido.length - 1) sRut += sInvertido.charAt(j);
    }
    //Pasamos al campo el valor formateado
    return sRut.toUpperCase();
  };

  const formato_telefono = (telefono) => {
    var sRut1 = telefono; //contador de para saber cuando insertar el . o la -
    var nPos = 0; //Guarda el rut invertido con los puntos y el guión agregado
    var sInvertido = ""; //Guarda el resultado final del rut como debe ser
    var sRut = "";
    for (var i = sRut1.length - 1; i >= 0; i--) {
      sInvertido += sRut1.charAt(i);
      nPos++;
      if (nPos === 4) {
        sInvertido += " ";
        nPos = 0;
      }
    }
    for (var j = sInvertido.length - 1; j >= 0; j--) {
      if (sInvertido.charAt(sInvertido.length - 1) !== ".")
        sRut += sInvertido.charAt(j);
      else if (j !== sInvertido.length - 1) sRut += sInvertido.charAt(j);
    }
    //Pasamos al campo el valor formateado
    return sRut.toUpperCase();
  };

  const formato_rut = (rut) => {
    var sRut1 = rut; //contador de para saber cuando insertar el . o la -
    var nPos = 0; //Guarda el rut invertido con los puntos y el guión agregado
    var sInvertido = ""; //Guarda el resultado final del rut como debe ser
    var sRut = "";
    for (var i = sRut1.length - 1; i >= 0; i--) {
      sInvertido += sRut1.charAt(i);
      if (i === sRut1.length - 1) sInvertido += "-";
      else if (nPos === 3) {
        sInvertido += ".";
        nPos = 0;
      }
      nPos++;
    }
    for (var j = sInvertido.length - 1; j >= 0; j--) {
      if (sInvertido.charAt(sInvertido.length - 1) !== ".")
        sRut += sInvertido.charAt(j);
      else if (j !== sInvertido.length - 1) sRut += sInvertido.charAt(j);
    }
    //Pasamos al campo el valor formateado
    return sRut.toUpperCase();
  };

  return (
    <div>
      {tieneError ? (
        <TextField
          //{...props}
          disabled={props.disabled}
          label={props.label}
          InputLabelProps={{
            shrink: props.type === "date" || value.length !== 0 ? true : false,
          }}
          error
          onFocus={onFocus}
          onBlur={onBlur}
          variant="outlined"
          fullWidth={true}
          className={[
            classes.formInput,
            props.type === "date" ? "fecha-input" : "",
          ]}
          InputProps={mask}
          onChange={(event) => onChangeValue(event.target.value)}
          onKeyPress={(event) => handleKeyPress(event, props.valueType)}
          helperText={textoError}
          value={value}
        />
      ) : (
        <TextField
          //{...props}
          disabled={props.disabled}
          label={props.label}
          InputLabelProps={{
            shrink:
              props.type === "date" || (value !== null && value.length !== 0)
                ? true
                : false,
          }}
          onFocus={(event) => onFocus(event, props.valueType)}
          onBlur={(event) => onBlur(event, props.valueType)}
          variant="outlined"
          fullWidth={true}
          className={[
            classes.formInput,
            props.type === "date" ? "fecha-input" : "",
          ]}
          InputProps={{ className: datoValido }}
          onChange={(event) => onChangeValue(event.target.value)}
          onKeyPress={(event) => handleKeyPress(event, props.valueType)}
          helperText={props.helperText !== undefined ? props.helperText : ""}
          value={value}
        />
      )}
    </div>
  );
}
