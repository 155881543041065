import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import FormInputDynamic from "../formularios/FormInputDynamic";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  title: {
    fontSize: 20,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formInput: {
    marginBottom: 20,
  },
}));

export default function DatosEmpresa({
  getFormulario = () => {
    return [];
  },
  onAddAtribute,
  preguntasEmpresa = [],
  preguntasCargo = [],
  firstStep,
  previousStep,
  enviarFormulario,
}) {
  const [validar, setValidar] = React.useState(false);
  const [actualizar, setActualizar] = React.useState(false);
  const [formulario] = React.useState({
    respuestasPreguntasCargo: [],
    respuestasPreguntasEmpresa: [],
  });
  const classes = useStyles();

  React.useEffect(() => {
    preguntasCargo.forEach((item, index) => {
      formulario["respuestasPreguntasCargo"][index] = {
        requiered: item.required,
      };
    });
    preguntasEmpresa.forEach((item, index) => {
      formulario["respuestasPreguntasEmpresa"][index] = {
        requiered: item.required,
      };
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (getFormulario()["rut"] === undefined) {
      firstStep();
    }
  }, [actualizar]); // eslint-disable-line react-hooks/exhaustive-deps

  const onAddAtributeEmpresa = (index, llave, valor) => {
    if (
      getFormulario()["respuestasPreguntasEmpresa"] === undefined ||
      getFormulario()["respuestasPreguntasEmpresa"] === null
    ) {
      onAddAtribute("respuestasPreguntasEmpresa", []);
    }
    var respuesta = getFormulario()["respuestasPreguntasEmpresa"][index];
    if (respuesta === undefined) {
      respuesta = {};
    }
    respuesta["idPregunta"] = llave;
    respuesta["respuesta"] = valor;
    respuesta["requiered"] =
      formulario["respuestasPreguntasEmpresa"][index]["requiered"];
    getFormulario()["respuestasPreguntasEmpresa"][index] = respuesta;
    formulario["respuestasPreguntasEmpresa"][index] = respuesta;
  };

  const onAddAtributeCargo = (index, llave, valor) => {
    if (
      getFormulario()["respuestasPreguntasCargo"] === undefined ||
      getFormulario()["respuestasPreguntasCargo"] === null
    ) {
      onAddAtribute("respuestasPreguntasCargo", []);
    }
    var respuesta = getFormulario()["respuestasPreguntasCargo"][index];
    if (respuesta === undefined) {
      respuesta = {};
    }
    respuesta["idPregunta"] = llave;
    respuesta["respuesta"] = valor;
    respuesta["requiered"] =
      formulario["respuestasPreguntasCargo"][index]["requiered"];
    getFormulario()["respuestasPreguntasCargo"][index] = respuesta;
    formulario["respuestasPreguntasCargo"][index] = respuesta;
  };

  const enviar = () => {
    if (validaFormulario()) {
      enviarFormulario();
    } else {
      setValidar(true);
      setActualizar(!actualizar);
    }
  };

  const validaFormulario = () => {
    let requerido = true;
    for (const property in formulario) {
      if (formulario[property].length > 0 && requerido) {
        for (const index in formulario[property]) {
          let parametros = formulario[property][index];
          if (Object.entries(parametros).length === 0) {
            return false;
          } else {
            if (
              requerido &&
              parametros["requiered"] !== undefined &&
              parametros["requiered"] &&
              (parametros["respuesta"] === undefined ||
                parametros["respuesta"].length === 0)
            ) {
              requerido = false;
              break;
            }
          }
        }
      }
    }
    return requerido;
  };

  const obtieneValorPregunta = (idPregunta, lista, index) => {
    const listaPregunta = getFormulario()[lista];
    if (listaPregunta !== null && listaPregunta !== undefined) {
      let pregunta = listaPregunta.find((item) => {
        if (item !== undefined && item !== null) {
          return item.idPregunta === idPregunta;
        } else {
          return undefined;
        }
      });
      if (pregunta !== undefined) {
        if (lista === "respuestasPreguntasEmpresa") {
          onAddAtributeEmpresa(index, idPregunta, pregunta.respuesta);
        } else {
          onAddAtributeCargo(index, idPregunta, pregunta.respuesta);
        }
        return pregunta.respuesta;
      }
    }
    return "";
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <div
          style={{
            borderWidth: 1,
            borderColor: "#888888",
            borderStyle: "solid",
            padding: 10,
            borderRadius: 15,
            marginBottom: 25,
          }}
        >
          <CardHeader
            title="Preguntas asociadas al proceso de postulación"
            subheader={
              <div style={{ marginBottom: 10, fontStyle: "italic" }}>
                Los campos (<span style={{ color: "#e94949" }}>*</span>) son
                obligatorios.
              </div>
            }
          />
          {preguntasEmpresa &&
            preguntasEmpresa.map((item, index) => {
              return (
                <FormInputDynamic
                  key={index}
                  activo={item.activo}
                  requiered={item.required}
                  validar={validar}
                  type={item.type}
                  label={item.label}
                  list={item.list}
                  index={index}
                  value={obtieneValorPregunta(
                    item.idFormulario,
                    "respuestasPreguntasEmpresa",
                    index
                  )}
                  name={item.idFormulario}
                  onDataChange={onAddAtributeEmpresa}
                  // mask={item.mask!==null?{ mask: item.mask }:{}}
                />
              );
            })}
          {preguntasCargo &&
            preguntasCargo.map((item, index) => {
              return (
                <FormInputDynamic
                  key={index}
                  activo={item.activo}
                  requiered={item.required}
                  validar={validar}
                  type={item.type}
                  label={item.label}
                  name={item.idFormulario}
                  list={item.list}
                  index={index}
                  value={obtieneValorPregunta(
                    item.idFormulario,
                    "respuestasPreguntasCargo",
                    index
                  )}
                  //name={item.idFormulario}
                  onDataChange={onAddAtributeCargo}
                  //mask={item.mask!==null?{ mask: item.mask }:{}}
                />
              );
            })}
        </div>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          style={{ backgroundColor: "#f6a623", color: "#ffffff" }}
          onClick={enviar}
        >
          Enviar
        </Button>
        <Button variant="contained" onClick={previousStep}>
          Anterior
        </Button>
      </CardActions>
    </Card>
  );
}
