const preguntas = [
  {
    grupoPreguntas: [
      {
        id: 0,
        preguntas: [
          {
            id: 1,
            letra: "a",
            pregunta: "Entusiasta",
          },
          {
            id: 2,
            letra: "b",
            pregunta: "Rápido/a",
          },
          {
            id: 3,
            letra: "c",
            pregunta: "Lógico/a",
          },
          {
            id: 4,
            letra: "d",
            pregunta: "Apacible",
          },
        ],
      },
    ],
  },
  {
    grupoPreguntas: [
      {
        id: 1,
        preguntas: [
          {
            id: 1,
            letra: "a",
            pregunta: "Cauteloso/a",
          },
          {
            id: 2,
            letra: "b",
            pregunta: "Decidido/a",
          },
          {
            id: 3,
            letra: "c",
            pregunta: "Receptivo/a",
          },
          {
            id: 4,
            letra: "d",
            pregunta: "Bondadoso/a",
          },
        ],
      },
    ],
  },
  {
    grupoPreguntas: [
      {
        id: 2,
        preguntas: [
          {
            id: 1,
            letra: "a",
            pregunta: "Amigable",
          },
          {
            id: 2,
            letra: "b",
            pregunta: "Preciso/a",
          },
          {
            id: 3,
            letra: "c",
            pregunta: "Franco/a",
          },
          {
            id: 4,
            letra: "d",
            pregunta: "Tranquilo/a",
          },
        ],
      },
    ],
  },
  {
    grupoPreguntas: [
      {
        id: 3,
        preguntas: [
          {
            id: 1,
            letra: "a",
            pregunta: "Elocuente",
          },
          {
            id: 2,
            letra: "b",
            pregunta: "Controlado/a",
          },
          {
            id: 3,
            letra: "c",
            pregunta: "Tolerante",
          },
          {
            id: 4,
            letra: "d",
            pregunta: "Decisivo",
          },
        ],
      },
    ],
  },
  {
    grupoPreguntas: [
      {
        id: 4,
        preguntas: [
          {
            id: 1,
            letra: "a",
            pregunta: "Atrevido/a",
          },
          {
            id: 2,
            letra: "b",
            pregunta: "Concienzudo/a",
          },
          {
            id: 3,
            letra: "c",
            pregunta: "Comunicativo/a",
          },
          {
            id: 4,
            letra: "d",
            pregunta: "Moderado/a",
          },
        ],
      },
    ],
  },
  {
    grupoPreguntas: [
      {
        id: 5,
        preguntas: [
          {
            id: 1,
            letra: "a",
            pregunta: "Ameno/a",
          },
          {
            id: 2,
            letra: "b",
            pregunta: "Ingenioso/a",
          },
          {
            id: 3,
            letra: "c",
            pregunta: "Investigador/a",
          },
          {
            id: 4,
            letra: "d",
            pregunta: "Acepta Riesgos",
          },
        ],
      },
    ],
  },
  {
    grupoPreguntas: [
      {
        id: 6,
        preguntas: [
          {
            id: 1,
            letra: "a",
            pregunta: "Expresivo/a",
          },
          {
            id: 2,
            letra: "b",
            pregunta: "Cuidadoso/a",
          },
          {
            id: 3,
            letra: "c",
            pregunta: "Dominante",
          },
          {
            id: 4,
            letra: "d",
            pregunta: "Sensible",
          },
        ],
      },
    ],
  },
  {
    grupoPreguntas: [
      {
        id: 7,
        preguntas: [
          {
            id: 1,
            letra: "a",
            pregunta: "Extrovertido/a",
          },
          {
            id: 2,
            letra: "b",
            pregunta: "Precavido/a",
          },
          {
            id: 3,
            letra: "c",
            pregunta: "Constante",
          },
          {
            id: 4,
            letra: "d",
            pregunta: "Impaciente",
          },
        ],
      },
    ],
  },
  {
    grupoPreguntas: [
      {
        id: 8,
        preguntas: [
          {
            id: 1,
            letra: "a",
            pregunta: "Discreto/a",
          },
          {
            id: 2,
            letra: "b",
            pregunta: "Complaciente",
          },
          {
            id: 3,
            letra: "c",
            pregunta: "Encantador/a",
          },
          {
            id: 4,
            letra: "d",
            pregunta: "Insistente",
          },
        ],
      },
    ],
  },
  {
    grupoPreguntas: [
      {
        id: 9,
        preguntas: [
          {
            id: 1,
            letra: "a",
            pregunta: "Valeroso/a ",
          },
          {
            id: 2,
            letra: "b",
            pregunta: "Anima a los demás",
          },
          {
            id: 3,
            letra: "c",
            pregunta: "Pacífico/a",
          },
          {
            id: 4,
            letra: "d",
            pregunta: "Perfeccionista",
          },
        ],
      },
    ],
  },
  {
    grupoPreguntas: [
      {
        id: 10,
        preguntas: [
          {
            id: 1,
            letra: "a",
            pregunta: "Reservado/a ",
          },
          {
            id: 2,
            letra: "b",
            pregunta: "Atento/a",
          },
          {
            id: 3,
            letra: "c",
            pregunta: "Osado/a ",
          },
          {
            id: 4,
            letra: "d",
            pregunta: " Alegre",
          },
        ],
      },
    ],
  },
  {
    grupoPreguntas: [
      {
        id: 11,
        preguntas: [
          {
            id: 1,
            letra: "a",
            pregunta: "Estimulante ",
          },
          {
            id: 2,
            letra: "b",
            pregunta: "Gentil",
          },
          {
            id: 3,
            letra: "c",
            pregunta: "Perceptivo/a",
          },
          {
            id: 4,
            letra: "d",
            pregunta: "Independiente",
          },
        ],
      },
    ],
  },
  {
    grupoPreguntas: [
      {
        id: 12,
        preguntas: [
          {
            id: 1,
            letra: "a",
            pregunta: "Competitivo/a ",
          },
          {
            id: 2,
            letra: "b",
            pregunta: "Considerado/a",
          },
          {
            id: 3,
            letra: "c",
            pregunta: "Alegre",
          },
          {
            id: 4,
            letra: "d",
            pregunta: "Sagaz",
          },
        ],
      },
    ],
  },
  {
    grupoPreguntas: [
      {
        id: 13,
        preguntas: [
          {
            id: 1,
            letra: "a",
            pregunta: "Meticuloso/a",
          },
          {
            id: 2,
            letra: "b",
            pregunta: "Obediente",
          },
          {
            id: 3,
            letra: "c",
            pregunta: "Ideas firmes",
          },
          {
            id: 4,
            letra: "d",
            pregunta: "Alentador/a",
          },
        ],
      },
    ],
  },
  {
    grupoPreguntas: [
      {
        id: 14,
        preguntas: [
          {
            id: 1,
            letra: "a",
            pregunta: "Popular",
          },
          {
            id: 2,
            letra: "b",
            pregunta: "Reflexivo/a",
          },
          {
            id: 3,
            letra: "c",
            pregunta: "Tenaz",
          },
          {
            id: 4,
            letra: "d",
            pregunta: "Calmado/a",
          },
        ],
      },
    ],
  },
  {
    grupoPreguntas: [
      {
        id: 15,
        preguntas: [
          {
            id: 1,
            letra: "a",
            pregunta: "Analítico/a",
          },
          {
            id: 2,
            letra: "b",
            pregunta: "Audaz",
          },
          {
            id: 3,
            letra: "c",
            pregunta: "Leal",
          },
          {
            id: 4,
            letra: "d",
            pregunta: "Promotor/a",
          },
        ],
      },
    ],
  },
  {
    grupoPreguntas: [
      {
        id: 16,
        preguntas: [
          {
            id: 1,
            letra: "a",
            pregunta: "Sociable",
          },
          {
            id: 2,
            letra: "b",
            pregunta: "Paciente",
          },
          {
            id: 3,
            letra: "c",
            pregunta: "Autosuficiente",
          },
          {
            id: 4,
            letra: "d",
            pregunta: "Certero/a",
          },
        ],
      },
    ],
  },
  {
    grupoPreguntas: [
      {
        id: 17,
        preguntas: [
          {
            id: 1,
            letra: "a",
            pregunta: "Adaptable",
          },
          {
            id: 2,
            letra: "b",
            pregunta: "Resuelto/a ",
          },
          {
            id: 3,
            letra: "c",
            pregunta: "Prevenido/a",
          },
          {
            id: 4,
            letra: "d",
            pregunta: "Vivaz",
          },
        ],
      },
    ],
  },
  {
    grupoPreguntas: [
      {
        id: 18,
        preguntas: [
          {
            id: 1,
            letra: "a",
            pregunta: "Agresivo/a",
          },
          {
            id: 2,
            letra: "b",
            pregunta: "Impetuoso/a ",
          },
          {
            id: 3,
            letra: "c",
            pregunta: "Amistoso/a",
          },
          {
            id: 4,
            letra: "d",
            pregunta: "Discerniente",
          },
        ],
      },
    ],
  },
  {
    grupoPreguntas: [
      {
        id: 19,
        preguntas: [
          {
            id: 1,
            letra: "a",
            pregunta: "De trato facil",
          },
          {
            id: 2,
            letra: "b",
            pregunta: "Compasivo/a",
          },
          {
            id: 3,
            letra: "c",
            pregunta: "Cauto/a  ",
          },
          {
            id: 4,
            letra: "d",
            pregunta: "Habla directo",
          },
        ],
      },
    ],
  },
  {
    grupoPreguntas: [
      {
        id: 20,
        preguntas: [
          {
            id: 1,
            letra: "a",
            pregunta: "Evaluador/a ",
          },
          {
            id: 2,
            letra: "b",
            pregunta: "Generoso/a ",
          },
          {
            id: 3,
            letra: "c",
            pregunta: "Animado/a",
          },
          {
            id: 4,
            letra: "d",
            pregunta: "Persistente",
          },
        ],
      },
    ],
  },
  {
    grupoPreguntas: [
      {
        id: 21,
        preguntas: [
          {
            id: 1,
            letra: "a",
            pregunta: "Impulsivo/a",
          },
          {
            id: 2,
            letra: "b",
            pregunta: "Cuida los detalles",
          },
          {
            id: 3,
            letra: "c",
            pregunta: "Enérgico/a",
          },
          {
            id: 4,
            letra: "d",
            pregunta: "Tranquilo/a",
          },
        ],
      },
    ],
  },
  {
    grupoPreguntas: [
      {
        id: 22,
        preguntas: [
          {
            id: 1,
            letra: "a",
            pregunta: "Sociable",
          },
          {
            id: 2,
            letra: "b",
            pregunta: "Sistemático/a",
          },
          {
            id: 3,
            letra: "c",
            pregunta: "Vigoroso/a",
          },
          {
            id: 4,
            letra: "d",
            pregunta: "Tolerante",
          },
        ],
      },
    ],
  },
  {
    grupoPreguntas: [
      {
        id: 23,
        preguntas: [
          {
            id: 1,
            letra: "a",
            pregunta: "Cautivador/a ",
          },
          {
            id: 2,
            letra: "b",
            pregunta: "Contento/a",
          },
          {
            id: 3,
            letra: "c",
            pregunta: "Exigente",
          },
          {
            id: 4,
            letra: "d",
            pregunta: "Apegado a normas",
          },
        ],
      },
    ],
  },
  {
    grupoPreguntas: [
      {
        id: 24,
        preguntas: [
          {
            id: 1,
            letra: "a",
            pregunta: "Le agrada discutir ",
          },
          {
            id: 2,
            letra: "b",
            pregunta: "Metódico/a",
          },
          {
            id: 3,
            letra: "c",
            pregunta: "Comedido/a",
          },
          {
            id: 4,
            letra: "d",
            pregunta: "Desenvuelto/a",
          },
        ],
      },
    ],
  },
  {
    grupoPreguntas: [
      {
        id: 25,
        preguntas: [
          {
            id: 1,
            letra: "a",
            pregunta: "Jovial",
          },
          {
            id: 2,
            letra: "b",
            pregunta: "Preciso/a",
          },
          {
            id: 3,
            letra: "c",
            pregunta: "Directo/a ",
          },
          {
            id: 4,
            letra: "d",
            pregunta: "Ecuánime",
          },
        ],
      },
    ],
  },
  {
    grupoPreguntas: [
      {
        id: 26,
        preguntas: [
          {
            id: 1,
            letra: "a",
            pregunta: "Inquieto/a ",
          },
          {
            id: 2,
            letra: "b",
            pregunta: "Amable",
          },
          {
            id: 3,
            letra: "c",
            pregunta: "Elocuente",
          },
          {
            id: 4,
            letra: "d",
            pregunta: "Cuidadoso/a",
          },
        ],
      },
    ],
  },
  {
    grupoPreguntas: [
      {
        id: 27,
        preguntas: [
          {
            id: 1,
            letra: "a",
            pregunta: "Prudente",
          },
          {
            id: 2,
            letra: "b",
            pregunta: "Pionero/a",
          },
          {
            id: 3,
            letra: "c",
            pregunta: "Espontáneo/a",
          },
          {
            id: 4,
            letra: "d",
            pregunta: "Colaborador/a",
          },
        ],
      },
    ],
  },
];

export default preguntas;
