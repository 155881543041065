import { Grid, Radio } from "@material-ui/core";
import React from "react";

const URL_POSTULANTE = process.env.REACT_APP_URL_BASE_API_POSTULANTE;

export default function PreguntaPRP({ index, pregunta, uuidTest }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event) => {
    setValue(parseInt(event.target.value));
    guardarRespuesta(parseInt(event.target.value));
  };

  const esSeleccionado = (min, max) => {
    return (pregunta.tipo === "asc" ? min : max) === value;
  };

  const guardarRespuesta = async (value) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        idPregunta: pregunta.id,
        respuesta: value,
        uuid: uuidTest,
      }),
      redirect: "follow",
    };
    try {
      const fetchResponse = await fetch(
        URL_POSTULANTE + "test/prp",
        requestOptions
      );
      const data = await fetchResponse.json();
      if (data.codigoRespuesta !== 200) {
        console.error("Error al insertar la respuesta.");
      }
    } catch (e) {
      return e;
    }
  };

  return (
    <Grid container className="fila-pregunta">
      {/*<Grid
        item
        style={{
          backgroundColor: "#eaeaea",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderColor: "#dedede",
          borderWidth: 1,
          borderStyle: "solid",
        }}
        xs={1}
      >
        {index}
      </Grid>*/}
      <Grid
        item
        style={{
          backgroundColor: "#eaeaea",
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          fontSize: "1.2rem",
          borderColor: "#dedede",
          borderWidth: 1,
          borderStyle: "solid",
        }}
        xs={7}
      >
        {index}.-
        {pregunta.glosa}
      </Grid>
      <Grid
        item
        style={{
          borderColor: "#dedede",
          borderWidth: 1,
          borderStyle: "solid",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        xs={1}
      >
        <span style={{ display: "none" }}>TOTALMENTE DE ACUERDO</span>
        <Radio
          value={pregunta.tipo === "asc" ? 1 : 5}
          checked={esSeleccionado(1, 5)}
          name="radio-buttons"
          color="#F47C06"
          onChange={handleChange}
        />
      </Grid>
      <Grid
        item
        style={{
          borderColor: "#dedede",
          borderWidth: 1,
          borderStyle: "solid",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        xs={1}
      >
        <span style={{ display: "none" }}>DE ACUERDO</span>
        <Radio
          value={pregunta.tipo === "asc" ? 2 : 4}
          checked={esSeleccionado(2, 4)}
          name="radio-buttons"
          color="#F47C06"
          onChange={handleChange}
        />
      </Grid>
      <Grid
        item
        style={{
          borderColor: "#dedede",
          borderWidth: 1,
          borderStyle: "solid",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        xs={1}
      >
        <span style={{ display: "none" }}>INDECISO</span>
        <Radio
          value={pregunta.tipo === "asc" ? 3 : 3}
          checked={esSeleccionado(3, 3)}
          name="radio-buttons"
          color="#F47C06"
          onChange={handleChange}
        />
      </Grid>
      <Grid
        item
        style={{
          borderColor: "#dedede",
          borderWidth: 1,
          borderStyle: "solid",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        xs={1}
      >
        <span style={{ display: "none" }}>EN DESACUERDO</span>
        <Radio
          value={pregunta.tipo === "asc" ? 4 : 2}
          checked={esSeleccionado(4, 2)}
          name="radio-buttons"
          color="#F47C06"
          onChange={handleChange}
        />
      </Grid>
      <Grid
        item
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderColor: "#dedede",
          borderWidth: 1,
          borderStyle: "solid",
          textAlign: "center",
        }}
        xs={1}
      >
        <span style={{ display: "none" }}>TOTALMENTE EN DESACUERDO</span>
        <Radio
          value={pregunta.tipo === "asc" ? 5 : 1}
          checked={esSeleccionado(5, 1)}
          name="radio-buttons"
          color="default"
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
}
