import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  table: {
    maxWidth: 650,
    margin: "auto",
  },
});

const PreguntaDisc = (props) => {
  const classes = useStyles();

  const [deshabilitar, setDeshabilitar] = useState(true);
  const [preguntaActual, setPreguntaActual] = useState();
  const [respuesta] = useState({
    idPregunta: props.pregunta.grupoPreguntas[0].id,
    mas: null,
    menos: null,
  });

  const guardarRespuesta = (letra, tipoResp, e) => {
    let continua = true;
    if (
      respuesta.mas !== null &&
      respuesta.mas === letra &&
      tipoResp === "menos"
    ) {
      e.preventDefault();
      continua = false;
    }
    if (
      respuesta.menos !== null &&
      respuesta.menos === letra &&
      tipoResp === "mas"
    ) {
      e.preventDefault();
      continua = false;
    }
    if (continua) {
      respuesta[tipoResp] = letra;
      if (respuesta.mas !== null && respuesta.menos !== null) {
        setDeshabilitar(false);
      }
    }
  };

  const otraPagina = () => {
    props.setNumeroPregunta(props.currentStep + 1);
    props.responderTest(respuesta, props.currentStep);
    props.nextStep();
  };

  const enviar = () => {
    props.responderTest(respuesta, props.currentStep);
    props.enviar();
  };

  useEffect(() => {
    setPreguntaActual(props.pregunta.grupoPreguntas[0].id);
    props.setNumeroPregunta(props.currentStep);
  }, [deshabilitar]);

  return (
    <div>
      <TableContainer className={classes.table} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Pregunta</TableCell>
              <TableCell align="right">Más</TableCell>
              <TableCell align="right">Menos</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.pregunta.grupoPreguntas.map((set, indexSet) =>
              set.preguntas.map((pregunta, indexPregunta) => (
                <TableRow key={indexPregunta}>
                  <TableCell component="th" scope="row">
                    {pregunta.pregunta}
                  </TableCell>
                  <TableCell align="right">
                    <input
                      type="radio"
                      id="male"
                      name="mas"
                      value="mas"
                      onClick={(e) => {
                        guardarRespuesta(pregunta.letra, "mas", e);
                      }}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <input
                      type="radio"
                      id="male"
                      name="menos"
                      value="menos"
                      onClick={(e) => {
                        guardarRespuesta(pregunta.letra, "menos", e);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{ marginTop: "20px", textAlign: "end", paddingRight: "20px" }}
      >
        {preguntaActual < 27 ? (
          <Button
            variant="contained"
            style={
              deshabilitar
                ? { backgroundColor: "#e0e0e0", color: "#8a8a8a" }
                : { backgroundColor: "#f6a623", color: "#ffffff" }
            }
            onClick={otraPagina}
            disabled={deshabilitar}
          >
            Siguiente
          </Button>
        ) : (
          <Button
            variant="contained"
            style={
              deshabilitar
                ? { backgroundColor: "#e0e0e0", color: "#8a8a8a" }
                : { backgroundColor: "#f6a623", color: "#ffffff" }
            }
            onClick={enviar}
            disabled={deshabilitar}
          >
            Enviar
          </Button>
        )}
      </div>
    </div>
  );
};
export default PreguntaDisc;
