import React, { useState, useEffect } from "react";
import { Button, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const URL_POSTULANTE = process.env.REACT_APP_URL_BASE_API_POSTULANTE;

const useStyles = makeStyles({
  table: {
    maxWidth: 800,
    margin: "auto",
  },
});

const PreguntaWonderlic = ({ pregunta, uuidTest }) => {
  const classes = useStyles();
  const [respuesta, setRespuesta] = React.useState("");

  const guardarRespuesta = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        idPregunta: pregunta.id,
        respuesta: respuesta,
        uuid: uuidTest,
      }),
      redirect: "follow",
    };
    try {
      const fetchResponse = await fetch(
        URL_POSTULANTE + "test/wonderlic",
        requestOptions
      );
      const data = await fetchResponse.json();
      if (data.codigoRespuesta !== 200) {
        console.error("Error al insertar la respuesta.");
      }
    } catch (e) {
      return e;
    }
  };

  const handlerRespuesta = (value) => {
    if (pregunta.expresionRegular !== null) {
      let regex = new RegExp(pregunta.expresionRegular);

      if (regex.test(value)) {
        setRespuesta(value);
      }
    } else {
      setRespuesta(value);
    }
  };

  useEffect(() => {}, []);

  return (
    <div style={{ marginBottom: 20, paddingInline: 30 }}>
      <TableContainer className={classes.table} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h6" gutterBottom component="div">
                  {`${pregunta.orden}.- ${pregunta.glosa}`}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pregunta.listaJSON !== null && (
              <TableRow key={`opciones-${pregunta.id}`}>
                <TableCell align="left">
                  {pregunta.listaJSON[0].label !== undefined &&
                    pregunta.listaJSON.map((item) => (
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        component="div"
                      >{`${item.valor}.- ${item.label}`}</Typography>
                    ))}
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    {pregunta.listaJSON[0].label === undefined &&
                      pregunta.listaJSON.map((item) => (
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          component="div"
                        >
                          {item}
                        </Typography>
                      ))}
                  </div>
                </TableCell>
              </TableRow>
            )}

            {pregunta.imagen !== null && (
              <TableRow>
                <TableCell align="center">
                  <img alt="" src={pregunta.imagen} width={300} />
                </TableCell>
              </TableRow>
            )}
            <TableRow key={`respuesta-${pregunta.id}`}>
              <TableCell>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  className="respuesta-wonderlic"
                >
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    component="div"
                    style={{ alignSelf: "center", marginLeft: 10 }}
                  >
                    {pregunta.textoAyuda}
                  </Typography>
                  {pregunta.unidad !== null && (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <TextField
                        id="outlined-basic"
                        label="Respuesta"
                        variant="outlined"
                        onBlur={guardarRespuesta}
                        value={respuesta}
                        onChange={(event) =>
                          handlerRespuesta(event.target.value)
                        }
                        style={{ width: 200 }}
                      />

                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        component="div"
                        style={{ alignSelf: "center", marginLeft: 10 }}
                      >
                        {pregunta.unidad}
                      </Typography>
                    </div>
                  )}
                  {pregunta.unidad === null && (
                    <TextField
                      id="outlined-basic"
                      label="Respuesta"
                      variant="outlined"
                      onBlur={guardarRespuesta}
                      value={respuesta}
                      onChange={(event) => handlerRespuesta(event.target.value)}
                      style={{ width: 200 }}
                    />
                  )}
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default PreguntaWonderlic;
