import React, { useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  makeStyles,
  TableCell,
  TableRow,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CustomTextInput from "../inputs/CustomTextInput";
import FormInputDynamic from "../formularios/FormInputDynamic";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  title: {
    fontSize: 20,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formInput: {
    marginBottom: 20,
  },
  button: {
    backgroundColor: "#e27e07",
    color: "white",
    marginBottom: 20,
  },
}));

export default function Referencias(props) {
  const classes = useStyles();

  const [referencias, setReferencias] = useState([]);
  const [validaEmail, setValidaEmail] = useState([]);
  const [validar, setValidar] = React.useState(false);
  const [actualizar, setActualizar] = useState(false);

  const addElement = () => {
    setValidar(false);
    setActualizar(!actualizar);
    setReferencias((elements) => [
      ...elements,
      {
        nombre: null,
        telefono: null,
        cargo: null,
        empresa: null,
        email: null,
      },
    ]);
    setValidaEmail((elements) => [...elements, true]);
  };

  const removeElement = (index) => {
    setValidar(false);
    if (
      props.formulario()["referencias"] !== undefined &&
      props.formulario()["referencias"].length > 0
    ) {
      eliminaElemento(props.formulario()["referencias"], index, (listaAux) => {
        props.formulario()["referencias"] = listaAux;
      });
    }
    eliminaElemento(referencias, index, setReferencias);
    eliminaElemento(validaEmail, index, setValidaEmail);
    setActualizar(!actualizar);
  };

  const eliminaElemento = (lista, index, setLista) => {
    let listaAux = [];
    let pos;
    for (pos in lista) {
      if (parseInt(pos) !== parseInt(index)) {
        listaAux.push(JSON.parse(JSON.stringify(lista[pos])));
      }
    }
    if (tieneCamposRequeridos() && listaAux.length === 0) {
      props.setModal({
        closeModal: true,
        subtitulo: "Ingreso Proceso Postulante",
        texto: "Debe ingresar al menos una referencia",
      });
    }
    setLista(listaAux);
  };

  const validaEmailHandler = (nombre, valido, index) => {
    validaEmail[index] = valido;
  };

  const onAddAtribute = (index, llave, valor) => {
    if (props.formulario()["referencias"] === undefined) {
      props.onDataChange("referencias", []);
    }
    var educacion = props.formulario()["referencias"][index];
    if (educacion === undefined) {
      educacion = {};
    }
    educacion[llave] = valor;
    props.formulario()["referencias"][index] = educacion;
    referencias[index][llave] = valor;
  };

  const validaFormulario = () => {
    let requerido = true;
    if (referencias !== undefined) {
      if (referencias.length > 0) {
        for (const index in referencias) {
          let parametros = referencias[index];
          if (Object.entries(parametros).length === 0) {
            requerido = false;
          } else {
            for (const key in Object.entries(parametros)) {
              const element = Object.entries(parametros)[key];
              let pregunta = props.preguntas.find((p) => {
                return p.name === element[0];
              });
              if (
                requerido &&
                pregunta !== undefined &&
                pregunta["requerido"] === 1 &&
                (element[1] === undefined ||
                  element[1] === null ||
                  element[1].length === 0)
              ) {
                requerido = false;
                break;
              }
            }
          }
        }
      }
    }
    return requerido;
  };

  const siguiente = () => {
    if (validaFormulario()) {
      if (props.enviarFormulario !== undefined) {
        props.enviarFormulario();
      } else {
        props.nextStep();
      }
    } else {
      props.setModal({
        closeModal: true,
        subtitulo: "Ingreso Proceso Postulante",
        texto: "Completar todos los campos obligatorios antes de avanzar",
      });
      setValidar(true);
      setActualizar(!actualizar);
    }
  };

  const tieneCamposRequeridos = () => {
    let tieneRequerido = props.preguntas.find(
      (pregunta) => pregunta.requerido === 1
    );
    if (tieneRequerido !== undefined) {
      return true;
    }
    return false;
  };

  React.useEffect(() => {
    if (tieneCamposRequeridos() && referencias.length === 0) {
      addElement();
    }
  }, [referencias.length]);

  React.useEffect(() => {
    if (props.value?.length > 0) {
      setReferencias(props.value);
    }
  }, [props.value]);

  React.useEffect(() => {
    if (props.formulario()["rut"] === undefined) {
      props.firstStep();
    } else {
      if (
        referencias.length === 0 &&
        props.formulario()["referencias"] !== undefined &&
        props.formulario()["referencias"].length > 0
      ) {
        setReferencias(props.formulario()["referencias"]);
      }
    }
  }, [props.formulario, actualizar]);

  const Bloque = ({
    title,
    listaNombre,
    children,
    mostrarCampoObligatorios = true,
  }) => {
    return (
      <div
        style={{
          borderWidth: 1,
          borderColor: "#888888",
          borderStyle: "solid",
          padding: 10,
          borderRadius: 15,
          marginBottom: 25,
        }}
      >
        <TableRow style={{ justifyContent: "space-between", display: "flex" }}>
          <TableCell style={{ borderBlockWidth: 0 }}>
            <CardHeader
              title={title}
              subheader={
                mostrarCampoObligatorios ? (
                  <div style={{ marginBottom: 10, fontStyle: "italic" }}>
                    Los campos (<span style={{ color: "#e94949" }}>*</span>) son
                    obligatorios.
                  </div>
                ) : (
                  ""
                )
              }
            />
          </TableCell>
          <TableCell style={{ borderBlockWidth: 0 }}>
            <Button
              variant="contained"
              onClick={() => addElement()}
              className={classes.button}
            >
              Agregar
            </Button>
          </TableCell>
        </TableRow>
        {children}
      </div>
    );
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Bloque
          title={"Referencias"}
          subheader={
            <div style={{ marginBottom: 10, fontStyle: "italic" }}>
              Los campos (<span style={{ color: "#e94949" }}>*</span>) son
              obligatorios.
            </div>
          }
          mostrarCampoObligatorios={referencias.length > 0}
        >
          {referencias.length > 0 &&
            referencias.map((item, index) => {
              return (
                <div key={"referencia-" + index}>
                  <CardHeader
                    title={"N°" + (index + 1)}
                    action={
                      <IconButton
                        aria-label="settings"
                        onClick={() => removeElement(index)}
                      >
                        <CloseIcon />
                      </IconButton>
                    }
                  />
                  {props.preguntas !== undefined &&
                    props.preguntas.length > 0 &&
                    props.preguntas.map((pregunta, num) => {
                      return (
                        <FormInputDynamic
                          key={`pregunta-${num}`}
                          activo={pregunta.activo === 0 ? false : true}
                          requiered={pregunta.requerido === 0 ? false : true}
                          validar={validar}
                          className={classes.formInput}
                          type={pregunta.tipoInput.descripcion}
                          label={pregunta.label}
                          name={pregunta.name}
                          index={index}
                          rows={pregunta.name === "funciones" ? 5 : 1}
                          helperText={pregunta.helperText}
                          valueType={pregunta.valueType}
                          multiple={pregunta.multiple}
                          maxLength={pregunta.maxLength}
                          url={pregunta.url}
                          multiline={
                            pregunta.name === "funciones" ? true : false
                          }
                          value={
                            item !== undefined
                              ? item[pregunta.name]?.toString()
                              : ""
                          }
                          onDataChange={onAddAtribute}
                        />
                      );
                    })}
                </div>
              );
            })}
        </Bloque>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          style={{ backgroundColor: "#f6a623", color: "#ffffff" }}
          onClick={siguiente}
        >
          {props.enviarFormulario !== undefined ? "Enviar" : "Continuar"}
        </Button>
        <Button variant="contained" onClick={props.previousStep}>
          Anterior
        </Button>
      </CardActions>
    </Card>
  );
}
