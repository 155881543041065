import React from "react";
import CustomRadioInput from "../inputs/CustomRadioInput";
import CustomSelectInput from "../inputs/CustomSelectInput";
import CustomTextInput from "../inputs/CustomTextInput";
import { makeStyles } from "@material-ui/core/styles";
import CustomDateInput from "../inputs/CustomDateInput";
import { colors } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formInput: {
    marginBottom: 20,
  },
}));

export default function FormInputDynamic(props) {
  const classes = useStyles();
  const { multiple, multiline, ...otrosProps } = props;

  React.useEffect(() => {});

  if (props.activo !== true) {
    return false;
  }

  const getLabel = () => {
    let label = <span>{props.label}</span>;
    if (props.requiered === true || props.requiered === "true") {
      label = (
        <span>
          {`${props.label} `} <span style={{ color: "#e94949" }}>*</span>
        </span>
      );
    }
    return label;
  };

  switch (props.type) {
    case "TextInput":
      if (multiline) {
        return <CustomTextInput {...otrosProps} multiline label={getLabel()} />;
      } else {
        return <CustomTextInput {...otrosProps} label={getLabel()} />;
      }
    case "DropDown":
      if (multiple === 1) {
        return (
          <CustomSelectInput
            {...otrosProps}
            multiple={true}
            label={getLabel()}
          />
        );
      } else {
        return <CustomSelectInput {...otrosProps} label={getLabel()} />;
      }

    case "Radio":
      return (
        <CustomRadioInput
          {...props}
          valueIn={props.value}
          listIn={props.list}
          style={{ marginBottom: 20 }}
          label={getLabel()}
        />
      );
    case "DateSelect":
      return (
        <CustomDateInput {...props} formato={"yyyy-MM-dd"} label={getLabel()} />
      );
    /* return (
        <CustomTextInput
          {...props}
          // className={classes.formInput}
          // name={props.name}
          // label={props.label}
          type="date"
          format={"DD/MM/YYYY"}
          // value={props.value}
        />
      ); */
    default:
      break;
  }
}
