import { CardHeader, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect, useState } from "react";
import CustomSelectInput from "../inputs/CustomSelectInput";

const listaNivel = [
  { label: "Basico", value: "Basico" },
  { label: "Intermedio", value: "Intermedio" },
  { label: "Avanzado", value: "Avanzado" },
];

const useStyles = makeStyles((theme) => ({
  formInput: {
    marginBottom: 20,
  },
}));

export default function FormularioIdiomas({
  index,
  onAddAtribute,
  removeElement,
  validar,
  value,
  requiered,
}) {
  const classes = useStyles();
  const [idioma, setIdioma] = useState(value);

  useEffect(() => {}, [validar]);

  const getLabel = (labelIn) => {
    let label = <span>{labelIn}</span>;
    if (requiered === true || requiered === "true") {
      label = (
        <span>
          {`${labelIn} `} <span style={{ color: "#e94949" }}>*</span>
        </span>
      );
    }
    return label;
  };

  useEffect(() => {}, [idioma]);

  const onAddAtributeHandler = (nombreLista, index, llave, valor) => {
    let educacionAux = { ...idioma };
    educacionAux[llave] = valor;
    for (const key in Object.keys(educacionAux)) {
      const element = Object.keys(educacionAux)[key];
      onAddAtribute(nombreLista, index, element, educacionAux[element]);
    }
    setIdioma(educacionAux);
  };

  return (
    <div>
      <CardHeader
        title={"N°" + (index + 1)}
        action={
          <IconButton
            aria-label="settings"
            onClick={() => removeElement("idiomas", index)}
          >
            <CloseIcon />
          </IconButton>
        }
      />
      <CustomSelectInput
        autocomplete
        className={classes.formInput}
        onDataChange={onAddAtributeHandler}
        index={index}
        nombreLista={"idiomas"}
        name="idioma"
        label={getLabel("Idioma")}
        url="idiomas"
        requiered={requiered}
        value={idioma !== undefined ? idioma.idioma?.toString() : ""}
        validar={validar}
      />
      <CustomSelectInput
        className={classes.formInput}
        onDataChange={onAddAtributeHandler}
        index={index}
        nombreLista={"idiomas"}
        name="nivelOral"
        label={getLabel("Idioma Nivel Oral")}
        list={listaNivel}
        requiered={requiered}
        value={idioma !== undefined ? idioma.nivelOral?.toString() : ""}
        validar={validar}
      />
      <CustomSelectInput
        className={classes.formInput}
        onDataChange={onAddAtributeHandler}
        index={index}
        nombreLista={"idiomas"}
        name="nivelEscrito"
        label={getLabel("Idioma Nivel Escrito")}
        list={listaNivel}
        requiered={requiered}
        value={idioma !== undefined ? idioma.nivelEscrito?.toString() : ""}
        validar={validar}
      />
    </div>
  );
}
