import { Button, Divider, LinearProgress } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 300,
    flexGrow: 1,
    minWidth: 300,
    transform: "translateZ(0)",
    // The position fixed scoping doesn't work in IE 11.
    // Disable this demo to preserve the others.
    "@media all and (-ms-high-contrast: none)": {
      display: "none",
    },
  },
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    //border: '2px solid #000',
    borderRadius: 15,
    boxShadow: theme.shadows[5],
    //padding: theme.spacing(3, 3),
    marginInline: 20,
  },
  progresBar: {
    marginBottom: 20,
    marginInline: 20,
    backgroundColor: "#ffdca3",
  },
}));

export default function CutomModal({
  setMostrarModal,
  open,
  subtitulo,
  texto,
  closeModal,
  spinner,
}) {
  const classes = useStyles();
  const rootRef = React.useRef(null);

  const handleClose = () => {
    setMostrarModal(false);
  };

  useEffect(() => {}, [open]);

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={open}
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      className={classes.modal}
      container={() => rootRef.current}
    >
      <div className={classes.paper}>
        <h3 id="server-modal-title" style={{ paddingInline: 30 }}>
          {subtitulo}
        </h3>
        <Divider />
        <p id="server-modal-description" style={{ paddingInline: 30 }}>
          {texto}
        </p>
        {closeModal !== undefined && closeModal && (
          <div style={{ textAlign: "end", marginRight: 20, marginBottom: 20 }}>
            <Button
              variant="contained"
              onClick={handleClose}
              style={{ backgroundColor: "#f6a623", color: "#ffffff" }}
            >
              Aceptar
            </Button>
          </div>
        )}
        {spinner !== undefined && spinner && (
          <LinearProgress className={`${classes.progresBar} "progresBar"`} />
        )}
      </div>
    </Modal>
  );
}
