import React, { useEffect, useState } from "react";

import LinearProgress from "@material-ui/core/LinearProgress";
import { CardMedia } from "@material-ui/core";
const PasosQuiz = (props) => {
  const [valor, setValor] = useState();
  useEffect(() => {
    setValor((props.numeroPregunta * 100) / props.total);
  });
  return (
    <div style={{display: "flex", flexDirection: "column"}}>
    <div style={{display: "flex", flexDirection: "row" , alignItems: "flex-end", justifyContent: "space-between"}}>
      <h2>{`${props.title === undefined ? "Pregunta" : props.title} ${
        props.numeroPregunta
      } de ${props.total}`}</h2>
      <h2>
        <CardMedia
          style={{ width: "20vw", height: "12vw", maxWidth: 100, maxHeight: 60 }}
          image="logo-dark.png"
        />  
      </h2>
      </div>
      <div style={{ flex: 1}}>
        <LinearProgress variant="determinate" value={valor} />
      </div>
    </div>
  );
};
export default PasosQuiz;
