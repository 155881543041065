import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { FormHelperText, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

export default function CustomSelectInput(props) {
  const [value, setValue] = React.useState(
    props.multiple !== undefined ? [] : ""
  );
  const [inputValue, setInputValue] = React.useState("");
  const [list, setList] = React.useState([]);
  const [listCheck] = React.useState([]);
  const [tieneError, setTieneError] = React.useState(false);
  const [textoError, setTextoError] = React.useState("");
  const [datoValido, setDatoValido] = React.useState("");
  const [url, setUrl] = React.useState(props.url);

  const getData = () =>
    fetch(process.env.REACT_APP_URL_COMBOBOX + props.url).then((res) =>
      res.json()
    );

  const obtieneValor = () => {
    let valor = null;
    let event = {};
    if (props.autocomplete !== undefined) {
      valor = list.find((element) => element.value == props.value);
    } else {
      event = { target: { value: null } };
      if (props.multiple !== undefined) {
        if (
          props.value === undefined ||
          props.value === null ||
          props.value.length === 0
        ) {
          valor = [];
        } else {
          valor = [];
          props.value.forEach((dato) => {
            valor.push(dato.idLicencia);
          });
        }
      } else {
        valor = props.value;
      }
      event.target.value = valor;
    }

    return [event, valor];
  };

  const obtieneLista = () => {
    if (
      (list !== undefined &&
        list.length === 0 &&
        (props.disabled === undefined || !props.disabled)) ||
      url !== props.url
    ) {
      setUrl(props.url);
      if (props.url !== undefined) {
        getData().then((data) => {
          setList(
            data.listaItems === null
              ? [{ label: "Sin Datos", value: -1 }]
              : data.listaItems
          );
          if (props.multiple !== undefined) {
            var listIn = data.listaItems;
            if (listIn !== null) {
              listIn.forEach((element) => {
                listCheck[element.value + ""] = false;
              });
            }
          }
        });
      } else {
        if (props.list !== undefined) {
          setList(props.list);
        }
      }
    }
  };

  const comparaValorEntra = (datoIn) => {
    let iguales = true;
    if (value instanceof Array) {
      if (value.length !== datoIn.length) {
        iguales = false;
      } else {
        for (var i = 0; i < value.length; i++) {
          if (value[i].toString() !== datoIn[i].toString()) {
            iguales = false;
          }
        }
      }
    } else {
      iguales = value === datoIn;
    }
    return iguales;
  };

  const tieneDato = (dato) => {
    let tieneDato = true;
    if (dato === undefined || dato === null) {
      tieneDato = false;
    } else {
      if (dato instanceof Array) {
        if (dato.length === 0) {
          tieneDato = false;
        } else {
          tieneDato = false;
          dato.forEach((element) => {
            if (element.toString().length > 0) {
              tieneDato = true;
            }
          });
        }
      } else {
        tieneDato = dato.toString().length > 0;
      }
    }
    return tieneDato;
  };

  React.useEffect(() => {
    let datos = obtieneValor();
    if (props.disabled !== undefined && props.disabled) {
      setTieneError(false);
      setTextoError();
    } else {
      let mismoValor = comparaValorEntra(datos[1]);
      if (
        tieneDato(datos[1]) &&
        !tieneDato(value) &&
        !mismoValor &&
        list !== undefined &&
        list !== null &&
        list.length > 0
      ) {
        handleChange(datos[0], datos[1]);
      }
      let requiered =
        props.requiered !== undefined &&
        (props.requiered === true || props.requiered === "true");
      if (
        props.validar &&
        requiered &&
        value !== null &&
        value !== undefined &&
        value.length === 0 &&
        props.value !== null &&
        props.value !== undefined &&
        props.value.length === 0
      ) {
        setTieneError(true);
        setTextoError("El campo es requerido");
        if (props.validarFormulario !== undefined) {
          props.validarFormulario(props.name, true);
        }
      }
    }
    obtieneLista();
  }, [
    props.validar,
    props.formulario,
    listCheck,
    value,
    props.disabled,
    list,
    props.url,
    props.recargar,
    props.value,
  ]);

  const getAutocomplete = (tieneError) => {
    return (
      <FormControl fullWidth={true} className={props.className}>
        {list != null && (
          <Autocomplete
            disabled={props.disabled}
            value={value}
            onChange={handleChange}
            className={
              (tieneError ? "error-autocomplete" : "") + " " + datoValido
            }
            fullWidth={true}
            options={list}
            getOptionLabel={(option) =>
              option.label === undefined ? "" : option.label
            }
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label={props.label} variant="outlined" />
            )}
            getOptionSelected={(option, value) => {
              if (option.value === value.value) {
                setTieneError(false);
                setTextoError();
                return true;
              }
              return false;
            }}
          />
        )}
        {tieneError && (
          <FormHelperText className="Mui-error" style={{ marginInline: 14 }}>
            {textoError}
          </FormHelperText>
        )}
      </FormControl>
    );
  };

  const getComboBox = (tieneError) => {
    return (
      <div className="select-group">
        {tieneError ? (
          <FormControl
            disabled={props.disabled === undefined ? false : props.disabled}
            variant="outlined"
            className={props.className}
          >
            <InputLabel error id="demo-simple-select-outlined-label">
              {props.label}
            </InputLabel>
            <Select
              error
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={value}
              onChange={handleChange}
              label={props.label}
              renderValue={obtieneValores}
            >
              {list != null &&
                list.map((item, index) => {
                  return (
                    <MenuItem value={item.value} key={"menu-" + index}>
                      {props.multiple && (
                        <div>
                          <span
                            class="MuiButtonBase-root MuiIconButton-root PrivateSwitchBase-root-34 MuiCheckbox-root MuiCheckbox-colorPrimary PrivateSwitchBase-checked-35 MuiIconButton-colorPrimary"
                            aria-disabled="false"
                          >
                            <span class="MuiIconButton-label">
                              <input
                                style={{ display: "none" }}
                                class="PrivateSwitchBase-input-37"
                                id="checkbox-1"
                                type="checkbox"
                                data-indeterminate="false"
                                aria-label="primary checkbox"
                                value=""
                                checked={listCheck[item.value]}
                              />
                              {listCheck[item.value] && (
                                <svg
                                  class="MuiSvgIcon-root"
                                  focusable="false"
                                  viewBox="0 0 24 24"
                                  aria-hidden="true"
                                >
                                  <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
                                </svg>
                              )}
                              {!listCheck[item.value] && (
                                <svg
                                  class="MuiSvgIcon-root"
                                  focusable="false"
                                  viewBox="0 0 24 24"
                                  aria-hidden="true"
                                >
                                  <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
                                </svg>
                              )}
                            </span>
                            <span class="MuiTouchRipple-root"></span>
                          </span>
                        </div>
                      )}
                      {item.label}
                    </MenuItem>
                  );
                })}
            </Select>
            <FormHelperText className="Mui-error">{textoError}</FormHelperText>
          </FormControl>
        ) : (
          <FormControl
            disabled={props.disabled === undefined ? false : props.disabled}
            variant="outlined"
            style={{ marginBottom: 20 }}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              {props.label}
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={value}
              onChange={handleChange}
              label={props.label}
              className={datoValido}
              renderValue={obtieneValores}
            >
              {list != null &&
                list.map((item, index) => {
                  return (
                    <MenuItem value={item.value} key={"menu-" + index}>
                      {props.multiple && (
                        <div>
                          <span
                            class="MuiButtonBase-root MuiIconButton-root PrivateSwitchBase-root-34 MuiCheckbox-root MuiCheckbox-colorPrimary PrivateSwitchBase-checked-35 MuiIconButton-colorPrimary"
                            aria-disabled="false"
                          >
                            <span class="MuiIconButton-label">
                              <input
                                style={{ display: "none" }}
                                class="PrivateSwitchBase-input-37"
                                id="checkbox-1"
                                type="checkbox"
                                data-indeterminate="false"
                                aria-label="primary checkbox"
                                value=""
                                checked={listCheck[item.value]}
                              />
                              {listCheck[item.value] && (
                                <svg
                                  class="MuiSvgIcon-root"
                                  focusable="false"
                                  viewBox="0 0 24 24"
                                  aria-hidden="true"
                                >
                                  <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
                                </svg>
                              )}
                              {!listCheck[item.value] && (
                                <svg
                                  class="MuiSvgIcon-root"
                                  focusable="false"
                                  viewBox="0 0 24 24"
                                  aria-hidden="true"
                                >
                                  <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
                                </svg>
                              )}
                            </span>
                            <span class="MuiTouchRipple-root"></span>
                          </span>
                        </div>
                      )}
                      {item.label}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        )}
      </div>
    );
  };

  const handleChange = (event, newValue) => {
    setTieneError(false);
    setDatoValido("success");
    if (props.validarFormulario !== undefined) {
      props.validarFormulario(props.name, false);
    }
    if (props.multiple !== undefined) {
      var seleccionados = [];

      //se dejan todos como no checkeados

      let ultimoSeleccionado =
        event.target.value[event.target.value.length - 1];
      if (typeof ultimoSeleccionado !== "string") {
        ultimoSeleccionado = ultimoSeleccionado + "";
      }

      if (event.target.value instanceof Array) {
        for (let index = 0; index < listCheck.length; index++) {
          listCheck[index] = false;
        }
        event.target.value.forEach((dato) => {
          listCheck[parseInt(dato)] = true;
        });
      } else {
        listCheck[parseInt(ultimoSeleccionado)] =
          !listCheck[parseInt(ultimoSeleccionado)];
      }

      if (ultimoSeleccionado !== "1" && listCheck[1]) {
        listCheck[1] = false;
      }
      listCheck.forEach((element, index) => {
        if (!listCheck[1]) {
          if (listCheck[index]) {
            seleccionados.push(index);
          }
        } else {
          if (index !== 1) {
            listCheck[index] = false;
          } else {
            seleccionados.push(index);
          }
        }
      });
      setValue(seleccionados);
      setValorFomulario(seleccionados);
    } else {
      if (props.autocomplete !== undefined) {
        if (newValue !== null && newValue !== undefined) {
          setInputValue(newValue.label);
          setValue(newValue);
          setValorFomulario(newValue.value);
        }
      } else {
        setValue(event.target.value);
        setValorFomulario(event.target.value);
      }
    }
  };

  const setValorFomulario = (value) => {
    if (props.index !== undefined) {
      if (props.nombreLista !== undefined) {
        props.onDataChange(props.nombreLista, props.index, props.name, value);
      } else {
        props.onDataChange(props.index, props.name, value);
      }
    } else {
      props.onDataChange(props.name, value);
    }
    if (props.onChange !== undefined) {
      props.onChange(value);
    }
    if (props.setData !== undefined) {
      props.setData(value);
    }
  };

  const obtieneValores = () => {
    var texto = [];

    if (props.multiple !== undefined) {
      list.forEach((element) => {
        value.forEach((seleccionado) => {
          if (seleccionado == parseInt(element.value)) {
            texto.push(element.label);
          }
        });
      });
    } else {
      list.forEach((element) => {
        if (element.value == value) {
          texto.push(element.label);
        }
      });
    }
    return texto.toString();
  };

  if (props.autocomplete !== undefined) {
    return getAutocomplete(tieneError);
  } else {
    return getComboBox(tieneError);
  }
}
