import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    backgroundColor: "#0a5dc1",
    color: "#ffffff",
    display: "flex",
    justifyContent: "space-between",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
    color: "#ffffff",
  },
  pos: {
    marginBottom: 12,
    color: "#ffffff",
  },
});

export default function InfoCargo({
  cargo,
  mostrarDescripcion,
  setMostrarDescripcion = () => {},
}) {
  const classes = useStyles();

  React.useEffect(() => {}, [cargo]);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          Cargo de postulación:
        </Typography>
        <Typography variant="h5" component="h2">
          {cargo !== undefined && cargo.nombre}
        </Typography>
        {cargo !== undefined && cargo.descripcion !== undefined ? (
          <div>
            <Typography className={classes.pos} color="textSecondary">
              Descripción
            </Typography>
            <Typography variant="body2" component="p">
              <p>
                Se requiere profecional para poder trabajar en una empresa del
                gobierno...
              </p>
            </Typography>
          </div>
        ) : (
          ""
        )}
      </CardContent>
      <div
        style={{
          padding: 10,
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          onClick={() => setMostrarDescripcion(!mostrarDescripcion)}
          style={{
            height: 50,
            marginRight: 10,
            minWidth: 150,
            marginInline: 10,
            marginBlock: 10,
            backgroundColor: "#e27e07",
            color: "white",
          }}
        >
          {mostrarDescripcion ? "Postular aquí" : "Descripción del Proceso"}
        </Button>
        <CardMedia
          style={{ width: 105, height: 84, alignSelf: "center", minWidth: 105 }}
          image="Logos_v04_LOGO.png"
        />
      </div>
    </Card>
  );
}
