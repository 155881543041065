import React, { useState } from "react";
import VideoRecorder from "react-video-recorder";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { Button, Card, CardContent } from "@material-ui/core";

const objetoVideo = (nombreVideo, textoBoton, funcion) => {
  return {
    video: null,
    nombreVideo: nombreVideo,
    textoBoton: textoBoton,
    onClick: () => funcion(),
  };
};

export default function FormularioVideo({
  postulante,
  setDatosModal,
  setMostrarModal,
}) {
  const [videoActual, setVideoActual] = useState(0);
  const [actualizar, setActualizar] = useState(false);
  const [videos] = React.useState([
    objetoVideo("presentacion.webm", "Presentación", () => setVideoActual(0)),
    objetoVideo("motivacion.webm", "Motivación", () => setVideoActual(1)),
    objetoVideo("experiencia.webm", "Experiencia", () => setVideoActual(2)),
  ]);

  const cargarDocumento = () => {
    let postulanteVideo = new FormData();
    postulanteVideo.append("video", true);
    for (const index in videos) {
      let myFile = new File([videos[index].video], videos[index].nombreVideo, {
        type: videos[index].video.type,
      });
      postulanteVideo.append("file", myFile);
    }
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "TOKEN",
      },
      body: postulanteVideo,
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_URL_BASE_API_POSTULANTE}documentos/${postulante.idPostulante}/video`,
        requestOptions
      ).then((resp) => resolve(resp.json()));
    });
  };

  const videosCargados = () => {
    let sinVideo = videos.find((video) => video.video === null);
    if (sinVideo !== undefined) return false;
    return true;
  };

  return (
    <Card
      style={{
        padding: 10,
      }}
    >
      <CardContent
        style={{
          padding: 0,
          flexDirection: "column",
          display: "flex",
          height: "calc(100vh - 166px)",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {videos.map((video, index) => {
          return (
            <div
              style={{ display: videoActual === index ? "contents" : "none" }}
              key={`video-${index}`}
              id={`contenedor_video_${index}`}
            >
              <VideoRecorder
                chunkSize={250}
                constraints={{
                  audio: true,
                  video: true,
                }}
                dataAvailableTimeout={500}
                isFlipped
                isOnInitially
                replayVideoAutoplayAndLoopOff
                countdownTime={5000}
                showReplayControls
                onRecordingComplete={(videoBlob) => {
                  videos[videoActual].video = videoBlob;
                  setActualizar(!actualizar);
                }}
                t={(text) => {
                  let salida;
                  switch (text) {
                    case "PRESS":
                      salida = <div>PRESIONE</div>;
                      break;
                    case "REC":
                      salida = <div>GRABAR</div>;
                      break;
                    case "WHEN READY":
                      salida = <div>CUANDO ESTE LISTO</div>;
                      break;
                    case "Use another video":
                      salida = <div>Grabar otro video.</div>;
                      break;
                    default:
                      salida = <div>{text}</div>;
                      break;
                  }
                  return salida;
                }}
                timeLimit={30000}
                renderUnsupportedView={() => (
                  <div>
                    Este navegador no esta habilitado para grabar videos.
                  </div>
                )}
                renderLoadingView={() => <div>Cargando...</div>}
                renderErrorView={() => (
                  <div>
                    Se produjo un error al iniciar la cámara.
                    <br />
                    Reincie e intente de nuevo.
                  </div>
                )}
              />
            </div>
          );
        })}

        <div
          id={"contendor_botones"}
          style={{
            flexDirection: "row",
            display: "flex",
            marginBlock: 10,
          }}
        >
          {videos.map((video, index) => {
            return (
              <Button
                key={`button_video_${index}`}
                variant="contained"
                startIcon={
                  video.video === null ? (
                    <RemoveCircleIcon
                      htmlColor={"#888888"}
                      style={{ backgroundColor: "#fff", borderRadius: 10 }}
                    />
                  ) : (
                    <CheckCircleIcon
                      htmlColor={"#008000"}
                      style={{ backgroundColor: "#fff", borderRadius: 10 }}
                    />
                  )
                }
                style={{
                  height: 50,
                  marginRight: 10,
                  backgroundColor:
                    videoActual === index ? "#e27e07" : "#839FB2",
                  color: "white",
                }}
                onClick={() => video.onClick()}
              >
                {video.textoBoton}
              </Button>
            );
          })}
        </div>
        <Button
          variant="contained"
          style={{
            height: 50,
            width: "100%",
            marginRight: 10,
            backgroundColor: !videosCargados() ? "#839FB2" : "#e27e07",
            color: "white",
          }}
          disabled={!videosCargados()}
          onClick={() => {
            setDatosModal({
              closeModal: false,
              subtitulo: "Ingreso Proceso Postulante",
              texto: "Subiendo videos...",
              spinner: true,
            });
            setMostrarModal(true);
            cargarDocumento().then((resp) => {
              if (resp.codeResponse === 200) {
                setDatosModal({
                  closeModal: false,
                  subtitulo: "Proceso Postulante",
                  texto: "Gracias por participar en el proceso",
                });
                setMostrarModal(true);
              } else {
                setDatosModal({
                  closeModal: true,
                  subtitulo: "Proceso Postulante",
                  texto: resp.message,
                });
                setMostrarModal(true);
              }
            });
          }}
        >
          Enviar
        </Button>
      </CardContent>
    </Card>
  );
}
