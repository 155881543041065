import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import FormInputDynamic from "../formularios/FormInputDynamic";
import CustomTextInput from "../inputs/CustomTextInput";
import Validador from "../utiles/Validador";
import PostulanteService from "../services/PostulanteService";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  title: {
    fontSize: 20,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formInput: {
    marginBottom: 20,
  },
}));

export default function DatosPersonales(props) {
  const [region, setRegion] = React.useState(0);
  const [validar, setValidar] = React.useState(false);
  const [bloqueoRut, setBloqueoRut] = React.useState(false);
  const [bloqueoDatos, setBloqueoDatos] = React.useState(true);
  const validador = Validador();
  const [formulario] = React.useState({
    rut: true,
    nombre: true,
    apPaterno: true,
    apMaterno: true,
  });

  const classes = useStyles();

  React.useEffect(() => {
    if (props.preguntas !== undefined && props.preguntas.length > 0) {
      props.preguntas.map((item, index) => {
        if (item.requerido === 1) {
          formulario[item.name] = true;
        }
      });
    }
  }, [formulario]);

  const siguiente = () => {
    if (validaFormulario()) {
      if (props.enviarFormulario !== undefined) {
        props.enviarFormulario();
      } else {
        props.nextStep();
      }
    } else {
      props.setModal({
        closeModal: true,
        subtitulo: "Ingreso Proceso Postulante",
        texto: "Completar todos los campos obligatorios antes de avanzar",
      });
      setValidar(true);
    }
  };

  const validaFormulario = () => {
    let resultado = true;
    let preguntas = [
      { name: "nombre", requerido: 1 },
      { name: "apPaterno", requerido: 1 },
      { name: "apMaterno", requerido: 1 },
      ...props.preguntas,
    ];
    for (const key in preguntas) {
      const pregunta = preguntas[key];
      let datoFormulario = props.formulario()[pregunta.name];
      if (
        resultado &&
        pregunta["requerido"] === 1 &&
        (datoFormulario === undefined ||
          datoFormulario === null ||
          datoFormulario.length === 0)
      ) {
        resultado = false;
        break;
      }
    }
    return resultado;
  };

  const datosFormularios = (property, estado) => {
    formulario[property] = estado;
  };

  const obtienePostulante = (rutPostulante) => {
    setValidar(false);
    setBloqueoDatos(true);
    let rutValido = validador.rut(rutPostulante);
    if (
      rutPostulante !== undefined &&
      rutPostulante !== null &&
      rutPostulante.length > 0 &&
      rutValido
    ) {
      props.setModal({
        closeModal: false,
        subtitulo: "Ingreso Proceso Postulante",
        texto: "Cargando datos...",
        spinner: true,
      });
      PostulanteService.obtienePostulanteRut(
        props.idProceso,
        rutPostulante.replace(/[^0-9kK-]/g, "")
      ).then((resp) => {
        const respInicial = JSON.parse(JSON.stringify(resp));
        console.log(
          "🚀 ~ file: DatosPersonales.js ~ line 126 ~ ).then ~ respInicial",
          respInicial
        );
        if (resp.codigoRespuesta === 200) {
          if (resp.entidad !== null && respInicial.entidad !== undefined) {
            const postulante = JSON.parse(JSON.stringify(resp.entidad));
            PostulanteService.obtieneDocumentos(resp.entidad.idPostulante).then(
              (archivos) => {
                props.setMostrarModal(false);
                if (
                  archivos.codigoRespuesta === 200 &&
                  archivos.entidad.length > 0
                ) {
                  postulante["archivos"] = archivos.entidad.filter((a) => {
                    a["name"] = a.fileName;
                    return a.curriculum === 1;
                  });
                }
                props.setFormulario(postulante);
                props.setPostulante(postulante);
                setBloqueoRut(true);
                setBloqueoDatos(false);
              }
            );
          } else {
            props.setMostrarModal(false);
            setBloqueoDatos(false);
            setBloqueoRut(true);
          }
        } else {
          props.setModal({
            closeModal: true,
            subtitulo: "Ingreso Proceso Postulante",
            texto: "Se ha producido un error favor de intentar mas tarde",
          });
          setBloqueoDatos(true);
        }
      });
    } else {
      if (!rutValido) {
        setValidar(true);
      }
      if (rutPostulante.length === 0) {
        setValidar(false);
      }
    }
  };

  useEffect(() => {}, [validar, bloqueoRut, bloqueoDatos]);

  const getLabel = (label, requiered) => {
    let text = <span>{label}</span>;
    if (requiered === true || requiered === "true") {
      text = (
        <span>
          {`${label} `} <span style={{ color: "#e94949" }}>*</span>
        </span>
      );
    }
    return text;
  };

  return (
    <Card className={"datos-personales"}>
      <CardContent>
        <div
          style={{
            borderWidth: 1,
            borderColor: "#888888",
            borderStyle: "solid",
            padding: 10,
            borderRadius: 15,
            marginBottom: 25,
          }}
        >
          <CardHeader
            title="Datos Personales"
            subheader={
              <div style={{ marginBottom: 10, fontStyle: "italic" }}>
                Los campos (<span style={{ color: "#e94949" }}>*</span>) son
                obligatorios.
              </div>
            }
          />
          <CustomTextInput
            requiered="true"
            className={classes.formInput}
            label={getLabel("RUT", true)}
            name="rut"
            valueType="rut"
            maxLength="9"
            value={props.formulario()["rut"]}
            validar={validar}
            onBlurChange={obtienePostulante}
            validarFormulario={datosFormularios}
            disabled={bloqueoRut}
            onDataChange={props.onDataChange}
          />
          <CustomTextInput
            {...props}
            requiered="true"
            className={classes.formInput}
            label={getLabel("Nombres", true)}
            name="nombre"
            valueType="nombre"
            validar={validar}
            disabled={bloqueoDatos}
            value={props.formulario()["nombre"]}
            validarFormulario={datosFormularios}
            onChangeText={(text) => (props.formulario()["nombre"] = text)}
          />
          <CustomTextInput
            {...props}
            requiered="true"
            className={classes.formInput}
            label={getLabel("Apellido Paterno", true)}
            name="apPaterno"
            valueType="nombre"
            validar={validar}
            disabled={bloqueoDatos}
            value={props.formulario()["apPaterno"]}
            validarFormulario={datosFormularios}
            onChangeText={(text) => (props.formulario()["apPaterno"] = text)}
          />
          <CustomTextInput
            {...props}
            requiered="true"
            className={classes.formInput}
            label={getLabel("Apellido Materno", true)}
            name="apMaterno"
            valueType="nombre"
            validar={validar}
            disabled={bloqueoDatos}
            value={props.formulario()["apMaterno"]}
            validarFormulario={datosFormularios}
            onChangeText={(text) => (props.formulario()["apMaterno"] = text)}
          />
          {props.preguntas !== undefined &&
            props.preguntas.length > 0 &&
            props.preguntas.map((item, index) => {
              //console.log(item);
              return (
                <FormInputDynamic
                  key={`pregunta-${index}`}
                  activo={item.activo === 0 ? false : true}
                  requiered={item.requerido === 0 ? false : true}
                  validar={validar}
                  disabled={
                    item.name === "ciudad"
                      ? region === 0 && bloqueoDatos
                      : bloqueoDatos
                  }
                  className={classes.formInput}
                  type={item.tipoInput.descripcion}
                  label={item.label}
                  name={item.name}
                  index={index}
                  helperText={item.helperText}
                  valueType={item.valueType}
                  multiple={item.multiple}
                  url={item.name === "ciudad" ? item.url + region : item.url}
                  value={props.formulario()[item.name]}
                  onDataChange={(...dato) => {
                    if (item.name === "region") {
                      setRegion(dato[2]);
                    }
                    props.onDataChange(dato[1], dato[2]);
                  }}
                  validarFormulario={datosFormularios}
                />
              );
            })}
        </div>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          style={{ backgroundColor: "#f6a623", color: "#ffffff" }}
          onClick={siguiente}
        >
          {props.enviarFormulario !== undefined ? "Enviar" : "Continuar"}
        </Button>
      </CardActions>
    </Card>
  );
}
