import respMock from "./MockProceso";

const respuesta = {
  codigoRespuesta: null,
  mensaje: null,
  entidad: {},
};
const mock = false;

const service = {
  obtieneProceso: (uuidProceso) => {
    const requestOptions = {
      method: "GET",
    };
    return new Promise((resolve, reject) => {
      if (mock) {
        respuesta.codigoRespuesta = respMock.codeResponse;
        respuesta.mensaje = respMock.mensaje;
        respuesta.entidad = respMock;
        resolve(respuesta);
      } else {
        fetch(
          `${process.env.REACT_APP_URL_BASE_API_EMPRESA}formulario/${uuidProceso}`,
          requestOptions
        )
          .then((resp) => resp.json())
          .then((resp) => {
            respuesta.codigoRespuesta = resp.codeResponse;
            respuesta.mensaje = resp.mensaje;
            respuesta.entidad = resp;
            resolve(respuesta);
          });
      }
    });
  },
};

export default service;
