import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, CardMedia, Paper } from "@material-ui/core";
import PasosQuiz from "../utiles/PasosQuiz";
import StepWizard from "react-step-wizard";
import PreguntaDisc from "./PreguntaDisc";
import CutomModal from "../pasos/CutomModal";
import preguntas from "./PreguntasDisc";

const SERVER_URL_EMPRESA = process.env.REACT_APP_URL_BASE_API_EMPRESA;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
    margin: 20,
  },
  title: {
    fontSize: 20,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formInput: {
    marginBottom: 20,
  },
}));

export default function Preguntas(props) {
  const [uuIdTest] = React.useState(
    new URLSearchParams(window.location.search).get("uuIdTest")
  );
  const [total, setTotal] = React.useState(preguntas.length);
  const [respuestas, setRespuestas] = React.useState([]);
  const [numeroPregunta, setNumeroPregunta] = React.useState();
  const classes = useStyles();
  const [mostrarModal, setMostrarModal] = React.useState(false);
  const [tokenValido, setTokenValido] = React.useState(false);
  const [datosModal, setDatosModal] = React.useState({
    closeModal: false,
    subtitulo: null,
    texto: null,
  });

  const validaToken = async () => {
    try {
      const fetchResponse = await fetch(
        process.env.REACT_APP_URL_BASE_API_POSTULANTE +
          "test/validatoken/" +
          uuIdTest
      );
      const data = await fetchResponse.json();
      if (data.codeResponse !== 200) {
        setDatosModal({
          closeModal: false,
          subtitulo: "Test",
          texto: "El id de test ingresado no es valido",
        });
        setMostrarModal(true);
      } else {
        setTokenValido(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    if (uuIdTest == null) {
      setDatosModal({
        closeModal: false,
        subtitulo: "Test",
        texto: "Debe ingresar un ID de Test para continuar",
      });
      setMostrarModal(true);
    } else {
      setTotal(preguntas.length);
      if (!tokenValido) {
        validaToken();
      }
    }
  }, [mostrarModal]);

  const responderTest = (resp, setIndex) => {
    let respTemporal = respuestas;
    respTemporal[setIndex - 1] = resp;
    setRespuestas(respTemporal);
  };

  const enviar = async () => {
    let datos = {
      idTest: uuIdTest,
      listRespuestas: respuestas,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(datos),
      redirect: "follow",
    };

    try {
      const fetchResponse = await fetch(
        process.env.REACT_APP_URL_BASE_API_POSTULANTE + "test",
        requestOptions
      );
      const data = await fetchResponse.json();
      if (data.codeResponse === 200) {
        setDatosModal({
          closeModal: false,
          subtitulo: "Test",
          texto: "Gracias por participar en el proceso",
        });
        setMostrarModal(true);
      } else {
        setDatosModal({
          closeModal: true,
          subtitulo: "Test",
          texto: "Error al enviar formulario",
        });
        setMostrarModal(true);
      }
    } catch (e) {
      return e;
    }
  };

  return (
    <div style={{ overflow: "auto", minHeight: 800}}>
      <Card className={classes.root}>
        <CardContent>
          <PasosQuiz numeroPregunta={numeroPregunta} total={total} />
          <h2>
            Seleccione una palabra que más lo/a represente y una palabra que
            menos lo/a represente, de acuerdo a su percepción personal y su
            forma de trabajo.
          </h2>
          <StepWizard>
            {preguntas.map((e, i) => (
              <PreguntaDisc
                key={i}
                pregunta={e}
                setNumeroPregunta={setNumeroPregunta}
                responderTest={responderTest}
                enviar={enviar}
              />
            ))}
          </StepWizard>
        </CardContent>
      </Card>
      <CutomModal
        setMostrarModal={setMostrarModal}
        open={mostrarModal}
        closeModal={datosModal.closeModal}
        subtitulo={datosModal.subtitulo}
        texto={datosModal.texto}
      />
    </div>
  );
}
