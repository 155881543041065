import DateFnsUtils from "@date-io/date-fns";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useEffect } from "react";
import "../../App.css";
import DescripcionCargo from "../DescripcionCargo";
import InfoCargo from "../InfoCargo";
import CutomModal from "../pasos/CutomModal";
import CargoService from "../services/CargoService";
import ProcesoService from "../services/ProcesoService";
import FormularioInicio from "./FormularioInicio";
import FormularioPostulante from "./FormularioPostulante";
import FormularioVideo from "./FormularioVideo";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#033260",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function Formulario() {
  const [uuIdProceso] = React.useState(
    new URLSearchParams(window.location.search).get("uuIdProceso")
  );

  const [mostrarModal, setMostrarModal] = React.useState(false);
  const [mostrarDescripcion, setMostrarDescripcion] = React.useState(true);
  const [iniciaFormulario, setIniciaFormulario] = React.useState(true);
  const [iniciaVideo, setIniciaVideo] = React.useState(false);
  const [postulante, setPostulante] = React.useState();
  const [cargo, setCargo] = React.useState();
  const [proceso, setProceso] = React.useState();
  const [preguntasFormulario, setPreguntasFormulario] = React.useState([]);
  const [preguntasEmpresa, setPreguntasEmpresa] = React.useState([]);
  const [preguntasCargo, setPreguntasCargo] = React.useState([]);
  const [datosModal, setDatosModal] = React.useState({
    closeModal: false,
    subtitulo: null,
    texto: null,
  });

  const classes = useStyles();

  const obtieneCargo = () => {
    if (cargo === undefined) {
      ProcesoService.obtieneProceso(uuIdProceso).then((resp) => {
        if (resp.codigoRespuesta === 200) {
          setCargo(resp.entidad.cargo);
          setProceso(resp.entidad.proceso);
          if (
            resp.entidad.listPreguntaEmpresa !== undefined &&
            resp.entidad.listPreguntaEmpresa !== null &&
            resp.entidad.listPreguntaEmpresa.length > 0
          ) {
            setPreguntasEmpresa(resp.entidad.listPreguntaEmpresa);
          }
          if (
            resp.entidad.listPreguntasCargo !== undefined &&
            resp.entidad.listPreguntasCargo !== null &&
            resp.entidad.listPreguntasCargo.length > 0
          ) {
            setPreguntasCargo(resp.entidad.listPreguntasCargo);
          }
          obtienePreguntasCargo(resp.entidad.cargo.idCargo);
        } else {
          setModal({
            closeModal: false,
            texto: "Se produjo un error, favor de intentar mas tarde.",
          });
        }
      });
    }
  };

  const obtienePreguntasCargo = (idCargo) => {
    if (preguntasFormulario.length === 0) {
      CargoService.obtienePreguntas(idCargo).then((resp) => {
        if (resp.codigoRespuesta === 200) {
          setPreguntasFormulario(resp.entidad);
        } else {
          setModal({
            closeModal: false,
            texto: "Se produjo un error, favor de intentar mas tarde.",
          });
        }
      });
    }
  };

  const setModal = (opciones = {}) => {
    setDatosModal(opciones);
    setMostrarModal(true);
  };

  useEffect(() => {
    if (uuIdProceso === null) {
      setModal({
        closeModal: false,
        texto: "Debe ingresar un ID de Proceso para continuar",
      });
    } else {
      obtieneCargo();
    }
  }, []);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InfoCargo
              cargo={cargo}
              mostrarDescripcion={mostrarDescripcion}
              setMostrarDescripcion={setMostrarDescripcion}
            />
          </Grid>
          <Grid item xs={12}>
            {mostrarDescripcion &&
              cargo !== undefined &&
              proceso !== undefined && (
                <DescripcionCargo
                  cargo={cargo}
                  proceso={proceso}
                  setMostrarDescripcion={setMostrarDescripcion}
                />
              )}
            {!mostrarDescripcion && !iniciaFormulario && !iniciaVideo && (
              <FormularioInicio
                setIniciaFormulario={setIniciaFormulario}
                setIniciaVideo={setIniciaVideo}
              />
            )}
            {!mostrarDescripcion && iniciaVideo && cargo !== undefined && (
              <FormularioVideo
                postulante={postulante}
                setDatosModal={setDatosModal}
                setMostrarModal={setMostrarModal}
              />
            )}
            {!mostrarDescripcion && iniciaFormulario && cargo !== undefined && (
              <FormularioPostulante
                uuIdProceso={uuIdProceso}
                preguntas={preguntasFormulario}
                preguntasCargo={preguntasCargo}
                preguntasEmpresa={preguntasEmpresa}
                setModal={setModal}
                setMostrarModal={setMostrarModal}
                setIniciaVideo={setIniciaVideo}
                setIniciaFormulario={setIniciaFormulario}
                setPostulante={setPostulante}
              />
            )}
          </Grid>
        </Grid>
      </div>
      <CutomModal
        setMostrarModal={setMostrarModal}
        open={mostrarModal}
        closeModal={datosModal.closeModal}
        subtitulo={"Ingreso Proceso Postulante"}
        texto={datosModal.texto}
        spinner={datosModal.spinner}
      />
    </MuiPickersUtilsProvider>
  );
}
