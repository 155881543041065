import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import DISC from "./components/test/DISC";
import Formulario from "./components/formularios/Formulario";
import Wonderlic from "./components/test/Wonderlic";
import PRP from "./components/test/PRP";
import Barrat from "./components/test/Barrat";
import logger from "./components/utiles/Logger";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/postulante">
          <Formulario />
        </Route>
        <Route path="/test/WONDERLIC">
          <Wonderlic />
        </Route>
        <Route path="/test/PRP">
          <PRP />
        </Route>
        <Route path="/test/Barrat">
          <Barrat />
        </Route>
        <Route path="/test">
          <DISC />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

function Home() {
  return <h2>Home</h2>;
}
