import { CardHeader, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CustomTextInput from "../inputs/CustomTextInput";
import { useEffect } from "react";

export default function FormularioCapacitacion({
  index,
  onAddAtribute,
  removeElement,
  validar,
  value,
}) {
  useEffect(() => {}, validar);
  return (
    <div key={"certificacion-" + index}>
      <CardHeader
        title={"N°" + (index + 1)}
        action={
          <IconButton
            aria-label="settings"
            onClick={() => removeElement("certificaciones", index)}
          >
            <CloseIcon />
          </IconButton>
        }
      />
      <CustomTextInput
        requiered="true"
        onDataChange={onAddAtribute}
        index={index}
        nombreLista={"certificaciones"}
        label="Nombre"
        name="nombre"
        value={value}
        validar={validar}
      />
    </div>
  );
}
