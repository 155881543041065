import React, { useEffect, useState } from "react";
import { CardHeader, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FormInputDynamic from "./FormInputDynamic";

const useStyles = makeStyles((theme) => ({
  formInput: {
    marginBottom: 20,
  },
}));

export default function FormularioEducacion({
  index,
  onAddAtribute,
  removeElement,
  validar,
  value,
  recargar,
  preguntas,
}) {
  const classes = useStyles();

  const [tipoEntidad, setTipoEntidad] = useState(null);
  const [otro, setOtro] = React.useState(false);
  const [otraCarrera, setOtraCarrera] = React.useState(false);
  const [liceo, setLiceo] = React.useState(false);
  const [actualizar, setActualizar] = React.useState(false);
  const [educacion, setEducacion] = React.useState({});
  //const [validar, setValidar] = React.useState(validarIn);

  const datosFormularios = (property, estado, nombrelista, index) => {
    // setValidar(false);
    // formulario[nombrelista][index][property] = estado;
  };

  const seleccionaTipoEntidadEducativa = (selected) => {
    if (selected !== tipoEntidad) {
      let educacionAux = { tipoEntidadEducativa: selected };
      let liceo = false;
      switch (selected) {
        case "1":
          educacionAux["entEducativa"] = "";
          educacionAux["carrera"] = "";
          educacionAux["notaEgreso"] = "";
          break;
        case "2":
          educacionAux["liceo"] = "";
          educacionAux["notaEgreso"] = "";
          educacionAux["anioEgreso"] = "";
          educacionAux["especialidad"] = "";
          liceo = true;
          break;
        case "3":
          educacionAux["liceo"] = "";
          educacionAux["notaEgreso"] = "";
          educacionAux["anioEgreso"] = "";
          liceo = true;
          break;
        case "4":
          educacionAux["entEducativa"] = "";
          educacionAux["carrera"] = "";
          educacionAux["postGrado"] = "";
          educacionAux["notaEgreso"] = "";
          break;
        case "5":
          educacionAux["entEducativa"] = "";
          educacionAux["carrera"] = "";
          educacionAux["notaEgreso"] = "";
          break;
      }
      setLiceo(liceo);
      setOtro(false);
      setOtraCarrera(false);
      setTipoEntidad(selected);
      setActualizar(!actualizar);
      return educacionAux;
    }
    return educacion;
  };

  const onAddAtributeHandler = (nombreLista, index, llave, valor) => {
    let educacionAux = educacion;
    switch (llave) {
      case "tipoEntidadEducativa":
        educacionAux = seleccionaTipoEntidadEducativa(valor);
        break;
      case "liceo":
        if (tipoEntidad === "2" || tipoEntidad === "3") {
          onAddAtribute(nombreLista, index, "entEducativa", "282");
        }
        break;
      case "entEducativa":
        if (valor === "205") {
          setOtro(true);
        } else {
          setOtro(false);
        }
        break;
      case "carrera":
        if (valor === "345") {
          setOtraCarrera(true);
        } else {
          setOtraCarrera(false);
        }
        break;
    }
    educacionAux[llave] = valor;
    for (const key in Object.keys(educacionAux)) {
      const element = Object.keys(educacionAux)[key];
      onAddAtribute(nombreLista, index, element, educacionAux[element]);
    }
    setEducacion(educacionAux);
  };

  useEffect(() => {
    if (
      value !== undefined &&
      value !== null &&
      Object.entries(value).length > 0
    ) {
      let valor =
        value.tipoEntidadEducativa === undefined ||
        value.tipoEntidadEducativa === null
          ? ""
          : value.tipoEntidadEducativa.toString();
      if (valor !== tipoEntidad) {
        //datosEntrada["value"] = valor;
        setEducacion(JSON.parse(JSON.stringify(value)));
        seleccionaTipoEntidadEducativa(valor);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipoEntidad, otro, otraCarrera, validar, actualizar, recargar]);

  return (
    <div>
      <CardHeader
        title={"N°" + (index + 1)}
        action={
          <IconButton
            aria-label="settings"
            onClick={() => removeElement("educacion", index)}
          >
            <CloseIcon />
          </IconButton>
        }
      />
      {preguntas !== undefined &&
        preguntas.length > 0 &&
        preguntas.map((item, indexp) => {
          if (
            liceo &&
            (item.name === "entEducativa" || item.name === "carrera")
          ) {
            return <div key={`pregunta-${indexp}`}></div>;
          }
          if (!liceo && item.name === "liceo") {
            return <div key={`pregunta-${indexp}`}></div>;
          }
          if (!otro && item.name === "otro") {
            return <div key={`pregunta-${indexp}`}></div>;
          }
          if (!otraCarrera && item.name === "otraCarrera") {
            return <div key={`pregunta-${indexp}`}></div>;
          }
          if (tipoEntidad !== "4" && item.name === "postGrado") {
            return <div key={`pregunta-${indexp}`}></div>;
          }
          if (tipoEntidad !== "2" && item.name === "especialidad") {
            return <div key={`pregunta-${indexp}`}></div>;
          }
          //console.log(tipoEntidad, tipoEntidad !== 3, tipoEntidad !== 2, item);
          if (
            tipoEntidad !== "3" &&
            tipoEntidad !== "2" &&
            item.name === "anioEgreso"
          ) {
            return <div key={`pregunta-${indexp}`}></div>;
          }
          if (
            tipoEntidad === null &&
            (item.name === "postGrado" ||
              item.name === "notaEgreso" ||
              item.name === "anioEgreso" ||
              item.name === "especialidad")
          ) {
            return <div key={`pregunta-${indexp}`}></div>;
          }
          return (
            <FormInputDynamic
              key={`pregunta-${indexp}`}
              activo={item.activo === 0 ? false : true}
              requiered={item.requerido === 0 ? "false" : "true"}
              disabled={
                item.name === "tipoEntidadEducativa"
                  ? false
                  : tipoEntidad === null
              }
              testId={item.name}
              validar={validar}
              className={classes.formInput}
              type={item.tipoInput.descripcion}
              label={
                item.name === "notaEgreso" &&
                (tipoEntidad === "5" || tipoEntidad === "1")
                  ? "Nota Promedio"
                  : item.name === "notaEgreso" &&
                    (tipoEntidad === "2" || tipoEntidad === "3")
                  ? "Nota Egreso Enseñanza Media"
                  : item.label
              }
              name={item.name}
              index={index}
              helperText={item.helperText}
              valueType={item.valueType}
              multiple={item.multiple}
              url={item.url}
              nombreLista={"educacion"}
              /*onChange={(select) => {
                if (item.name === "tipoEntidadEducativa") {
                  handlerChangeSelect(select, index);
                }
              }}*/
              value={
                item.name === "tipoEntidadEducativa"
                  ? tipoEntidad
                  : educacion !== undefined
                  ? educacion[item.name]?.toString()
                  : ""
              }
              autocomplete
              onDataChange={onAddAtributeHandler}
              validarFormulario={datosFormularios}
            />
          );
        })}
    </div>
  );
}
