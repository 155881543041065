const respuesta = {
  codigoRespuesta: null,
  mensaje: null,
  entidad: {},
};

const service = {
  obtienePostulanteRut: (uuid, rut) => {
    const requestOptions = {
      method: "GET",
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_URL_BASE_API_POSTULANTE}postulante/rut/${rut}/proceso/${uuid}`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = resp.codeResponse;
          respuesta.mensaje = resp.mensaje;
          respuesta.entidad = resp.postulante;
          resolve(respuesta);
        });
    });
  },
  obtieneDocumentos: (idPostulante) => {
    const requestOptions = {
      method: "GET",
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_URL_BASE_API_POSTULANTE}documentos/${idPostulante}`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          respuesta.codigoRespuesta = 200;
          respuesta.mensaje = "OK";
          respuesta.entidad = resp;
          resolve(respuesta);
        });
    });
  },
};

export default service;
