import React, { Fragment, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { es } from "date-fns/locale";

const useStyles = makeStyles((theme) => ({
  formInput: {
    marginBottom: 20,
  },
  success: {
    borderColor: "#34b310",
    borderWidth: 2,
    marginBottom: 20,
  },
}));

export default function CustomDateInput({
  validar,
  formulario,
  index,
  nombreLista,
  name,
  onDataChange,
  label,
  disabled,
  required,
  formato,
  value,
}) {
  const classes = useStyles();
  const [valueAux, setValue] = React.useState(null);
  const [tieneError, setTieneError] = React.useState(false);

  useEffect(() => {
    if (validar) {
      setTieneError(true);
    }
  }, [validar, formulario]);

  useEffect(() => {
    if (value !== undefined && valueAux === null) {
      let datos = value.split("/");
      onChangeValue(
        new Date(parseInt(datos[2]), parseInt(datos[1]) - 1, parseInt(datos[0]))
      );
    }
  }, [value]);

  const onChangeValue = (date) => {
    let dia = date.getDate();
    if (dia < 10) {
      dia = "0" + dia;
    }
    let mes = date.getMonth() + 1;
    if (mes < 10) {
      mes = "0" + mes;
    }
    let dateText = date.getFullYear() + "-" + mes + "-" + dia;
    setTieneError(false);
    setValue(date);
    if (index !== undefined) {
      if (nombreLista !== undefined)
        onDataChange(nombreLista, index, name, dateText);
      else onDataChange(index, name, dateText);
    } else onDataChange(name, dateText);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
      {tieneError ? (
        <DatePicker
          label={label}
          disabled={disabled}
          required={required}
          autoOk={true}
          error
          className={
            valueAux !== null
              ? [classes.formInput, "success"]
              : classes.formInput
          }
          fullWidth="true"
          variant="modal"
          inputVariant="outlined"
          disableFuture
          openTo="year"
          format={formato}
          views={["year", "month", "date"]}
          value={valueAux}
          InputAdornmentProps={{ position: "start" }}
          onChange={(date) => onChangeValue(date)}
          onChangeCapture={(t) => console.log(t)}
          helperText={"Ingresar una fecha válida"}
        />
      ) : (
        <DatePicker
          label={label}
          disabled={disabled}
          required={required}
          autoOk={true}
          className={
            valueAux !== null
              ? [classes.formInput, "success"]
              : classes.formInput
          }
          fullWidth="true"
          variant="modal"
          inputVariant="outlined"
          disableFuture
          openTo="year"
          format={formato}
          views={["year", "month", "date"]}
          value={valueAux}
          InputAdornmentProps={{ position: "start" }}
          onChange={(date) => onChangeValue(date)}
        />
      )}
    </MuiPickersUtilsProvider>
  );
}
