export default function Validador() {
  return {
    rut: function (rutCompleto) {
      rutCompleto = rutCompleto.replace(/[^0-9kK]/g, "");
      var digv = rutCompleto.substr(rutCompleto.length - 1);
      var rut = rutCompleto.substr(0, rutCompleto.length - 1);
      if (digv === "K") digv = "k";
      return this.dv(rut) === digv;
    },
    dv: function (cuerpo) {
      // Calcular Dígito Verificador
      var suma = 0;
      var multiplo = 2;

      // Para cada dígito del Cuerpo
      for (var i = cuerpo.length - 1; i >= 0; i--) {
        // Sumar al Contador General
        suma = suma + multiplo * parseInt(cuerpo.charAt(i));

        // Consolidar Múltiplo dentro del rango [2,7]
        if (multiplo < 7) {
          multiplo = multiplo + 1;
        } else {
          multiplo = 2;
        }
      }

      // Calcular Dígito Verificador en base al Módulo 11
      var dvEsperado = 11 - (suma % 11);
      if (dvEsperado === 10) {
        dvEsperado = "k";
      }
      if (dvEsperado === 11) {
        dvEsperado = "0";
      }
      return dvEsperado.toString();
    },
    nota: function (dato) {
      var nota = parseInt(dato.replace(/[^0-9]/g, ""));
      return nota >= 20 && nota <= 70;
    },
    anio: function (dato, fechaActual) {
      var anio = parseInt(dato.replace(/[^0-9]/g, ""));
      return anio >= 1900 && anio <= fechaActual.getFullYear();
    },
    periodo: function (dato, fechaActual) {
      var periodo = dato.replace(/[^0-9]/g, "");
      var anio = parseInt(periodo.substr(2, periodo.length - 1));
      let respuesta = {};
      if (anio >= 1900 && anio <= fechaActual.getFullYear()) {
        respuesta["valido"] = true;
      } else {
        respuesta["valido"] = false;
        respuesta["anio"] = true;
      }
      var mes = parseInt(periodo.substr(0, periodo.length - 4));
      if (mes >= 1 && mes <= 12) {
        if (respuesta["valido"]) respuesta["valido"] = true;
      } else {
        respuesta["valido"] = false;
        respuesta["mes"] = true;
      }
      return respuesta;
    },
    email: function (email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
  };
}
