const preguntas = [
  {
    glosa: "Asistiría a un curso de prevención de accidentes de trabajo.",
    tipo: "desc",
  },
  {
    glosa:
      "Colaboraría en las acciones de prevención de accidentes de trabajo.",
    tipo: "desc",
  },
  { glosa: "Asistir a cursos de seguridad es perder el tiempo.", tipo: "asc" },
  {
    glosa: "La mayoría de los accidentes de trabajo se deben a la mala suerte.",
    tipo: "asc",
  },
  {
    glosa:
      "Las reuniones mensuales de seguridad sólo sirven para perder el tiempo.",
    tipo: "asc",
  },
  { glosa: "Por mucho ruido que haya siempre se acostumbra uno.", tipo: "asc" },
  {
    glosa:
      "Cada uno tiene su destino y si se ha de accidentar, por más seguro que trabaje, se accidenta.",
    tipo: "asc",
  },
  {
    glosa:
      "Cada trabajador debe pedir su ropa de seguridad cuando le corresponde.",
    tipo: "asc",
  },
  {
    glosa: "No vale la pena usar protecciones porque la faena cunde poco.",
    tipo: "asc",
  },
  {
    glosa:
      "Aunque una herramienta esté defectuosa con cuidado puede utilizarse.",
    tipo: "asc",
  },
  {
    glosa: "Colaboraría en la confección de carteles de seguridad.",
    tipo: "desc",
  },
  {
    glosa:
      "Para prevenirse de los accidentes no es necesario utilizar los elementos de protección personal.",
    tipo: "asc",
  },
  {
    glosa: "Los accidentes de trabajo sólo les ocurren a los demás.",
    tipo: "asc",
  },
  {
    glosa: "El trabajador descuidado pone en peligro a todos sus compañeros.",
    tipo: "desc",
  },
  { glosa: "Deberían organizarse mas campañas de seguridad.", tipo: "desc" },
  {
    glosa:
      "Cuando trabajamos no es posible tener en cuenta todas las normas de seguridad ",
    tipo: "asc",
  },
  {
    glosa: "No uso los lentes de seguridad porque me hacen trabajar incómodo.",
    tipo: "asc",
  },
  {
    glosa: "Se tendría que vigilar más la seguridad del trabajo.",
    tipo: "desc",
  },
  { glosa: "Tendría que haber más supervisión en seguridad.", tipo: "desc" },
  { glosa: "Las normas de seguridad no son problema mío.", tipo: "asc" },
  { glosa: "Aunque sea más lento, prefiero trabajar seguro.", tipo: "desc" },
  { glosa: "La culpa de los accidentes la tienen las máquinas.", tipo: "asc" },
  {
    glosa:
      "El experto en prevención es una persona muy necesaria en toda empresa.",
    tipo: "desc",
  },
  {
    glosa: "Me uniría a los que luchan para evitar los accidentes de trabajo",
    tipo: "desc",
  },
  { glosa: "El accidente no avisa, hay que estar alerta.", tipo: "desc" },
  {
    glosa: "Si debiese hacerlo, usaría todas las protecciones necesarias.",
    tipo: "desc",
  },
  { glosa: "La seguridad en el trabajo es cuestión de suerte.", tipo: "asc" },
  {
    glosa: "A veces uno debe arriesgarse para terminar el trabajo más rápido.",
    tipo: "asc",
  },
  {
    glosa:
      "Es imprescindible la buena colaboración de todos para una buena seguridad.",
    tipo: "desc",
  },
  {
    glosa:
      "Muchos jefes molestan a la gente hablando de la seguridad en el trabajo.",
    tipo: "asc",
  },
];

export default preguntas;
