import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  makeStyles,
  TableCell,
  TableRow,
} from "@material-ui/core";
import FormularioIdiomas from "../formularios/FormularioIdiomas";
import FormularioEducacion from "../formularios/FormularioEduacion";
import FormularioCurso from "../formularios/FormularioCurso";
import FormularioCapacitacion from "../formularios/FormularioCapacitacion";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
    marginBottom: 20,
  },
  title: {
    fontSize: 20,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formInput: {
    marginBottom: 20,
  },
  button: {
    backgroundColor: "#e27e07",
    color: "white",
    marginBottom: 20,
  },
}));

export default function DatosEducacion(props) {
  const classes = useStyles();

  const [educacion, setEducacion] = useState([]);
  const [idiomas, setIdiomas] = useState([]);
  const [certificaciones, setCertificaciones] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [actualizar, setActualizar] = React.useState(false);

  const [validar, setValidar] = React.useState(false);
  const [formulario] = React.useState({});

  const addElement = (lista) => {
    setValidar(false);
    if (formulario[lista] === undefined) {
      formulario[lista] = [];
    }
    formulario[lista].push({});

    switch (lista) {
      case "educacion":
        setEducacion([...educacion, {}]);
        break;
      case "idiomas":
        setIdiomas([
          ...idiomas,
          { idioma: "", nivelOral: "", nivelEscrito: "" },
        ]);
        break;
      case "certificaciones":
        setCertificaciones([...certificaciones, {}]);
        break;
      case "cursos":
        setCursos([...cursos, {}]);
        break;
      default:
        break;
    }
    setActualizar(!actualizar);
  };

  const removeElement = (nombreLista, index) => {
    setValidar(false);
    eliminaElemento(formulario[nombreLista], index, (listaAux) => {
      formulario[nombreLista] = listaAux;
    });
    if (
      props.formulario()[nombreLista] !== undefined &&
      props.formulario()[nombreLista].length > 0
    ) {
      eliminaElemento(props.formulario()[nombreLista], index, (listaAux) => {
        props.formulario()[nombreLista] = listaAux;
      });
    }
    switch (nombreLista) {
      case "educacion":
        eliminaElemento(educacion, index, setEducacion, 1, nombreLista);
        break;
      case "idiomas":
        eliminaElemento(idiomas, index, setIdiomas, 2, nombreLista);
        break;
      case "certificaciones":
        eliminaElemento(
          certificaciones,
          index,
          setCertificaciones,
          3,
          nombreLista
        );
        break;
      case "cursos":
        eliminaElemento(cursos, index, setCursos, 4, nombreLista);
        break;
      default:
        break;
    }
    setActualizar(!actualizar);
  };

  const eliminaElemento = (lista, index, setLista, subGrupo, nombreLista) => {
    let listaAux = [];
    let pos;
    for (pos in lista) {
      if (parseInt(pos) !== parseInt(index)) {
        listaAux.push(JSON.parse(JSON.stringify(lista[pos])));
      }
    }
    if (tieneCamposRequeridos(subGrupo) && listaAux.length === 0) {
      props.setModal({
        closeModal: true,
        subtitulo: "Ingreso Proceso Postulante",
        texto: "Debe ingresar al menos un item de " + nombreLista,
      });
    }
    setLista(listaAux);
  };

  const onAddAtribute = (nombreLista, index, llave, valor) => {
    if (props.formulario()[nombreLista] === undefined) {
      props.onDataChange(nombreLista, []);
      formulario[nombreLista] = [];
    }
    var objeto = props.formulario()[nombreLista][index];
    if (objeto === undefined) {
      objeto = {};
      if (nombreLista === "idioma") {
        objeto = { idioma: "", nivelOral: "", nivelEscrito: "" };
      }
    }
    objeto[llave] = valor;
    props.formulario()[nombreLista][index] = objeto;
    formulario[nombreLista][index] = objeto;
    switch (nombreLista) {
      case "educacion":
        educacion[index] = objeto;
        break;
      case "idiomas":
        idiomas[index] = objeto;
        break;
      case "cursos":
        cursos[index] = objeto;
        break;
      case "certificaciones":
        certificaciones[index] = objeto;
        break;
      default:
        break;
    }
  };

  const siguiente = () => {
    if (validaFormulario()) {
      if (props.enviarFormulario !== undefined) {
        props.enviarFormulario();
      } else {
        props.nextStep();
      }
    } else {
      props.setModal({
        closeModal: true,
        subtitulo: "Ingreso Proceso Postulante",
        texto: "Completar todos los campos obligatorios antes de avanzar",
      });
      setValidar(true);
      setActualizar(!actualizar);
    }
  };

  const validaFormulario = () => {
    let formularioValido = true;
    for (const index in educacion) {
      let item = educacion[index];
      if (Object.entries(item).length === 0) {
        formularioValido = false;
      }
      for (const key in Object.entries(item)) {
        const element = Object.entries(item)[key];
        const pregunta = props.preguntas.find((p) => p.name === element[0]);
        if (
          formularioValido &&
          pregunta !== undefined &&
          pregunta["requerido"] === 1 &&
          (element[1] === undefined ||
            element[1] === null ||
            element[1].length === 0)
        ) {
          formularioValido = false;
          break;
        }
      }
    }
    for (const index in idiomas) {
      let item = idiomas[index];
      if (Object.entries(item).length === 0) {
        formularioValido = false;
      }
      const pregunta = props.preguntas.find((p) => p.name === "idioma");
      for (const key in Object.entries(item)) {
        const element = Object.entries(item)[key];
        if (
          formularioValido &&
          pregunta !== undefined &&
          pregunta["requerido"] === 1 &&
          (element[1] === undefined ||
            element[1] === null ||
            element[1].length === 0)
        ) {
          formularioValido = false;
          break;
        }
      }
    }
    for (const index in certificaciones) {
      let item = certificaciones[index];
      if (Object.entries(item).length === 0) {
        formularioValido = false;
      }
      const pregunta = props.preguntas.find((p) => p.label === "Capacitación");
      const element = item["nombre"];
      if (
        formularioValido &&
        pregunta !== undefined &&
        pregunta["requerido"] === 1 &&
        (element === undefined || element === null || element.length === 0)
      ) {
        formularioValido = false;
        break;
      }
    }
    for (const index in cursos) {
      let item = cursos[index];
      if (Object.entries(item).length === 0) {
        formularioValido = false;
      }
      const pregunta = props.preguntas.find((p) => p.label === "Curso");
      const element = item["nombre"];
      if (
        formularioValido &&
        pregunta !== undefined &&
        pregunta["requerido"] === 1 &&
        (element === undefined || element === null || element.length === 0)
      ) {
        formularioValido = false;
        break;
      }
    }
    return formularioValido;
  };

  const tieneCamposRequeridos = (subGrupo) => {
    let preguntas = tienePreguntas(subGrupo);
    let tieneRequerido = preguntas.find((pregunta) => pregunta.requerido === 1);
    if (tieneRequerido !== undefined) {
      return true;
    }
    return false;
  };

  const getLista = (nombreLista) => {
    if (props.formulario() === undefined) {
      return [];
    }
    if (props.formulario()[nombreLista] === undefined) {
      return [];
    }
    return props.formulario()[nombreLista];
  };

  /*useEffect(() => {
    if (props.formulario()["rut"] === undefined) {
      props.firstStep();
    } else {
      if (
        educacion.length === 0 &&
        props.formulario()["educacion"] !== undefined &&
        props.formulario()["educacion"].length > 0
      ) {
        let datosEducacion = props.formulario()["educacion"];
        datosEducacion.forEach((dato) => {
          if (dato["carrera"] !== "345") {
            delete dato["otraCarrera"];
          }
          if (dato["entEducativa"] !== "205") {
            delete dato["otro"];
          }
          switch (parseInt(dato["tipoEntidadEducativa"])) {
            case 1:
              delete dato["anioEgreso"];
              delete dato["especialidad"];
              delete dato["liceo"];
              delete dato["postGrado"];
              break;
            case 2:
              delete dato["carrera"];
              delete dato["nombreCarrera"];
              delete dato["postGrado"];
              break;
            case 3:
              delete dato["especialidad"];
              delete dato["carrera"];
              delete dato["nombreCarrera"];
              delete dato["postGrado"];
              break;
            case 4:
              delete dato["anioEgreso"];
              delete dato["especialidad"];
              delete dato["liceo"];
              break;
            case 5:
              delete ["anioEgreso"];
              delete dato["especialidad"];
              delete dato["liceo"];
              delete dato["postGrado"];
              break;

            default:
              break;
          }
        });

        formulario["educacion"] = JSON.parse(JSON.stringify(datosEducacion));
        setEducacion(datosEducacion);
        setActualizar(!actualizar);
      }
      if (
        idiomas.length === 0 &&
        props.formulario()["idiomas"] !== undefined &&
        props.formulario()["idiomas"].length > 0
      ) {
        formulario["idiomas"] = props.formulario()["idiomas"];
        setIdiomas(JSON.parse(JSON.stringify(props.formulario()["idiomas"])));
        setActualizar(!actualizar);
      }
      if (
        certificaciones.length === 0 &&
        props.formulario()["certificaciones"] !== undefined &&
        props.formulario()["certificaciones"].length > 0
      ) {
        formulario["certificaciones"] = props.formulario()["certificaciones"];
        setCertificaciones(
          JSON.parse(JSON.stringify(props.formulario()["certificaciones"]))
        );
        setActualizar(!actualizar);
      }
      if (
        cursos.length === 0 &&
        props.formulario()["cursos"] !== undefined &&
        props.formulario()["cursos"].length > 0
      ) {
        formulario["cursos"] = props.formulario()["cursos"];
        setCursos(JSON.parse(JSON.stringify(props.formulario()["cursos"])));
        setActualizar(!actualizar);
      }
    }
  }, [actualizar, props.formulario]);*/

  useEffect(() => {
    if (props.formulario()["rut"] === undefined) {
      props.firstStep();
    }
  });

  useEffect(() => {
    let educacionLst = props.formulario()["educacion"];
    if (educacionLst !== undefined && educacionLst.length > 0) {
      educacionLst.forEach((dato) => {
        if (dato["carrera"] !== "345") {
          delete dato["otraCarrera"];
        }
        if (dato["entEducativa"] !== "205") {
          delete dato["otro"];
        }
        switch (parseInt(dato["tipoEntidadEducativa"])) {
          case 1:
            delete dato["anioEgreso"];
            delete dato["especialidad"];
            delete dato["liceo"];
            delete dato["postGrado"];
            break;
          case 2:
            delete dato["carrera"];
            delete dato["nombreCarrera"];
            delete dato["postGrado"];
            break;
          case 3:
            delete dato["especialidad"];
            delete dato["carrera"];
            delete dato["nombreCarrera"];
            delete dato["postGrado"];
            break;
          case 4:
            delete dato["anioEgreso"];
            delete dato["especialidad"];
            delete dato["liceo"];
            break;
          case 5:
            delete ["anioEgreso"];
            delete dato["especialidad"];
            delete dato["liceo"];
            delete dato["postGrado"];
            break;

          default:
            break;
        }
      });
      formulario["educacion"] = educacionLst;
      setEducacion(educacionLst);
    }
  }, [props.formulario()["educacion"]?.length]);

  useEffect(() => {
    if (tieneCamposRequeridos(1) && educacion.length === 0) {
      addElement("educacion");
    }
  }, [educacion.length]);

  useEffect(() => {
    let idiomasLst = props.formulario()["idiomas"];
    if (idiomasLst !== undefined && idiomasLst.length > 0) {
      formulario["idiomas"] = idiomasLst;
      setIdiomas(JSON.parse(JSON.stringify(idiomasLst)));
    }
  }, [props.formulario()["idiomas"]?.length]);

  useEffect(() => {
    if (tieneCamposRequeridos(2) && idiomas.length === 0) {
      addElement("idiomas");
    }
  }, [idiomas.length]);

  useEffect(() => {
    let certificacionesLst = props.formulario()["certificaciones"];
    if (certificacionesLst !== undefined && certificacionesLst.length > 0) {
      formulario["certificaciones"] = certificacionesLst;
      setCertificaciones(JSON.parse(JSON.stringify(certificacionesLst)));
    }
  }, [props.formulario()["certificaciones"]?.length]);

  useEffect(() => {
    if (tieneCamposRequeridos(3) && certificaciones.length === 0) {
      addElement("certificaciones");
    }
  }, [certificaciones.length]);

  useEffect(() => {
    let cursosLst = props.formulario()["cursos"];
    if (cursosLst !== undefined && cursosLst.length > 0) {
      formulario["cursos"] = cursosLst;
      setCursos(JSON.parse(JSON.stringify(cursosLst)));
    }
  }, [props.formulario()["cursos"]?.length]);

  useEffect(() => {
    if (tieneCamposRequeridos(4) && cursos.length === 0) {
      addElement("cursos");
    }
  }, [cursos.length]);

  const tienePreguntas = (subGrupo) => {
    let preguntas = props.preguntas.filter(
      (pregunta) => pregunta.subGrupo === subGrupo && pregunta.activo === 1
    );
    return preguntas !== undefined ? preguntas : [];
  };

  const Bloque = ({
    title,
    listaNombre,
    children,
    mostrarCampoObligatorios = true,
  }) => {
    return (
      <div
        style={{
          borderWidth: 1,
          borderColor: "#888888",
          borderStyle: "solid",
          padding: 10,
          borderRadius: 15,
          marginBottom: 25,
        }}
      >
        <TableRow style={{ justifyContent: "space-between", display: "flex" }}>
          <TableCell style={{ borderBlockWidth: 0 }}>
            <CardHeader
              title={title}
              subheader={
                mostrarCampoObligatorios ? (
                  <div style={{ marginBottom: 10, fontStyle: "italic" }}>
                    Los campos (<span style={{ color: "#e94949" }}>*</span>) son
                    obligatorios.
                  </div>
                ) : (
                  ""
                )
              }
            />
          </TableCell>
          <TableCell style={{ borderBlockWidth: 0 }}>
            <Button
              variant="contained"
              onClick={() => addElement(listaNombre)}
              className={classes.button}
            >
              Agregar
            </Button>
          </TableCell>
        </TableRow>
        {children}
      </div>
    );
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        {tienePreguntas(1).length > 0 && (
          <Bloque
            title={"Educación"}
            listaNombre={"educacion"}
            mostrarCampoObligatorios={educacion.length > 0}
          >
            {educacion.length > 0 &&
              educacion.map((item, index) => {
                let nuevoItem = JSON.parse(JSON.stringify(item));
                if (index > -1) {
                  return (
                    <FormularioEducacion
                      key={"educacion-" + index}
                      preguntas={tienePreguntas(1)}
                      validar={validar}
                      index={index}
                      value={nuevoItem}
                      onAddAtribute={onAddAtribute}
                      removeElement={removeElement}
                      recargar={actualizar}
                    />
                  );
                }
              })}
          </Bloque>
        )}
        {tienePreguntas(2).length > 0 && (
          <Bloque
            title={"Idiomas"}
            listaNombre={"idiomas"}
            mostrarCampoObligatorios={idiomas.length > 0}
          >
            {idiomas.length > 0 &&
              idiomas.map((item, index) => {
                if (index > -1) {
                  return (
                    <FormularioIdiomas
                      preguntas={tienePreguntas(2)}
                      key={"idioma-" + index}
                      requiered={tieneCamposRequeridos(2)}
                      validar={validar}
                      index={index}
                      value={item}
                      onAddAtribute={onAddAtribute}
                      removeElement={removeElement}
                    />
                  );
                }
              })}
          </Bloque>
        )}
        {tienePreguntas(3).length > 0 && (
          <Bloque
            title={"Certificaciones"}
            listaNombre={"certificaciones"}
            mostrarCampoObligatorios={certificaciones.length > 0}
          >
            {certificaciones.length > 0 &&
              certificaciones.map((item, index) => {
                if (index > -1) {
                  return (
                    <FormularioCapacitacion
                      key={"certificacion-" + index}
                      validar={validar}
                      index={index}
                      value={item !== undefined ? item.nombre?.toString() : ""}
                      onAddAtribute={onAddAtribute}
                      removeElement={removeElement}
                    />
                  );
                }
              })}
          </Bloque>
        )}
        {tienePreguntas(4).length > 0 && (
          <Bloque
            title={"Cursos o capacitaciones"}
            listaNombre={"cursos"}
            mostrarCampoObligatorios={cursos.length > 0}
          >
            {cursos.length > 0 &&
              cursos.map((item, index) => {
                if (index > -1) {
                  return (
                    <FormularioCurso
                      key={"curso-" + index}
                      validar={validar}
                      index={index}
                      value={item}
                      onAddAtribute={onAddAtribute}
                      removeElement={removeElement}
                    />
                  );
                }
              })}
          </Bloque>
        )}
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          style={{ backgroundColor: "#f6a623", color: "#ffffff" }}
          onClick={siguiente}
        >
          {props.enviarFormulario !== undefined ? "Enviar" : "Continuar"}
        </Button>
        <Button variant="contained" onClick={props.previousStep}>
          Anterior
        </Button>
      </CardActions>
    </Card>
  );
}
