import {
  AppBar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CutomModal from "../pasos/CutomModal";
import CountDownTimer from "../utiles/CountDownTimer";
import PreguntaBarrat from "./PreguntaBarrat";
import preguntas from "./PreguntasBarrat";
import "./Barrat.css";

const URL_POSTULANTE = process.env.REACT_APP_URL_BASE_API_POSTULANTE;

export default function Barrat() {
  const [uuIdTest] = React.useState(
    new URLSearchParams(window.location.search).get("uuIdTest")
  );
  const [aceptaCondiciones, setAceptaCondiciones] = useState(false);
  const [mostrarModal, setMostrarModal] = React.useState(false);
  const [tokenValido, setTokenValido] = React.useState(false);
  const [datosModal, setDatosModal] = React.useState({
    closeModal: false,
    subtitulo: null,
    texto: null,
  });

  const validaToken = async () => {
    try {
      const fetchResponse = await fetch(
        URL_POSTULANTE + "test/validatoken/" + uuIdTest
      );
      const data = await fetchResponse.json();
      if (data.codeResponse !== 200) {
        setDatosModal({
          closeModal: false,
          subtitulo: "Test Barrat",
          texto: "El id de test ingresado no es valido",
        });
        setMostrarModal(true);
      } else {
        setTokenValido(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const iniciarTest = async () => {
    try {
      const fetchResponse = await fetch(
        URL_POSTULANTE + "test/iniciar/" + uuIdTest
      );
      const data = await fetchResponse.json();
      if (data.codeResponse !== 200) {
        setDatosModal({
          closeModal: true,
          subtitulo: "Test Barrat",
          texto: "Se produjo un error no se puede iniciar el TEST.",
        });
        setMostrarModal(true);
      } else {
        setAceptaCondiciones(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const finalizarTest = async (acaboElTiempo = false) => {
    try {
      const fetchResponse = await fetch(
        URL_POSTULANTE + "test/finalizar/" + uuIdTest
      );
      const data = await fetchResponse.json();
      if (data.codeResponse !== 200) {
        setDatosModal({
          closeModal: true,
          subtitulo: "Test Barrat",
          texto: "Se produjo un error no se puede finalizar el TEST.",
        });
      } else {
        let mensaje = "Gracias por participar en el proceso.";
        if (acaboElTiempo) {
          mensaje =
            "Se ha acabado el tiempo asignado para responder el TEST. " +
            mensaje;
        }
        setDatosModal({
          closeModal: false,
          subtitulo: "Test Barrat",
          texto: mensaje,
        });
      }
      setMostrarModal(true);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (uuIdTest == null) {
      setDatosModal({
        closeModal: false,
        subtitulo: "Test Barrat",
        texto: "Debe ingresar un ID de Test para continuar",
      });
      setMostrarModal(true);
    } else {
      if (!tokenValido) {
        validaToken();
      }
    }
  }, [uuIdTest, tokenValido]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={{ flexGrow: 1 }}>
      {!aceptaCondiciones && (
        <Card style={{ margin: 30 }}>
          <CardContent>
            <Typography
              variant="h3"
              gutterBottom
              component="div"
              style={{
                textAlign: "center",
                textDecoration: "underline",
                marginBlock: 30,
              }}
            >
              INSTRUCCIONES
            </Typography>
            <Typography
              variant="body1"
              style={{
                textAlign: "center",
                marginBlock: 30,
              }}
              gutterBottom
            >
              LEA ESTA PÁGINA CUIDADOSAMENTE Y SIGA LAS INSTRUCCIONES
              EXACTAMENTE.
            </Typography>
            <br />
            <Typography variant="body1" gutterBottom>
              Las personas son diferentes en cuanto a la forma en que se
              comportan y piensan en distintas situaciones. Ésta es una prueba
              para medir algunas de las formas en que usted actúa y piensa. No
              se detenga demasiado tiempo en ninguna de las oraciones. Responda
              rápida y honestamente. Debe marcar <strong>una sola</strong> de
              las alternativas en cada pregunta.
            </Typography>
            <br />
            <Typography variant="body1" gutterBottom>
              La prueba se compone de 30 preguntas. Una vez abierto el link del
              test que se le ha enviado usted tendrá un máximo de
              <strong> 15 minutos </strong>
              para responder a tantas preguntas como pueda.
            </Typography>
          </CardContent>
          <CardActions style={{ justifyContent: "end" }}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#f47c06", color: "#ffffff" }}
              onClick={() => iniciarTest()}
            >
              Iniciar TEST
            </Button>
          </CardActions>
        </Card>
      )}
      {aceptaCondiciones && (
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="fixed" style={{ backgroundColor: "#0a5dc1" }}>
            <Toolbar style={{ justifyContent: "space-between" }}>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Test Barrat
              </Typography>
              <CountDownTimer
                onFinish={() => finalizarTest(true)}
                hoursMinSecs={{ hours: 0, minutes: 15, seconds: 0 }}
              />
            </Toolbar>
          </AppBar>
          <div
            style={{ marginTop: 64, backgroundColor: "#fff" }}
            className="contendor-preguntas"
          >
            <Grid aria-label="sticky table" stickyHeader>
              <Grid>
                <Grid container className="pregunta-header">
                  <Grid
                    item
                    style={{
                      backgroundColor: "#dedede",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    xs={7}
                  >
                    AFIRMACIÓN
                  </Grid>
                  <Grid
                    item
                    style={{
                      backgroundColor: "#dedede",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    xs={1}
                  >
                    RARAMENTE O NUNCA
                  </Grid>
                  <Grid
                    item
                    style={{
                      backgroundColor: "#dedede",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    xs={2}
                  >
                    OCASIONALMENTE
                  </Grid>
                  <Grid
                    item
                    style={{
                      backgroundColor: "#dedede",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    xs={1}
                  >
                    A MENUDOS
                  </Grid>
                  <Grid
                    item
                    style={{
                      backgroundColor: "#dedede",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    xs={1}
                  >
                    SIEMPRE O CASI SIEMPRE
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                style={{ overflow: "auto", height: "calc(100vh - 186px)" }}
                className="preguntas-barrat"
              >
                {preguntas !== undefined &&
                  preguntas.map((pregunta, index) => {
                    pregunta["id"] = index + 1;
                    return (
                      <PreguntaBarrat
                        index={index + 1}
                        key={`pregunta_barrat_${index}`}
                        pregunta={pregunta}
                        uuidTest={uuIdTest}
                      />
                    );
                  })}
              </Grid>
            </Grid>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: 20,
              height: 40,
              backgroundColor: "#0a5dc1",
            }}
          >
            <div style={{ textAlign: "end", width: "100%" }}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#f47c06",
                  color: "#ffffff",
                  width: "100%",
                }}
                onClick={() => finalizarTest()}
              >
                Finalizar TEST Barrat
              </Button>
            </div>
          </div>
        </Box>
      )}
      <CutomModal
        setMostrarModal={setMostrarModal}
        open={mostrarModal}
        closeModal={datosModal.closeModal}
        subtitulo={datosModal.subtitulo}
        texto={datosModal.texto}
      />
    </Box>
  );
}
