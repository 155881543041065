import { Button, Card, CardContent } from "@material-ui/core";
import React from "react";

export default function FormularioInicio({
  setIniciaFormulario,
  setIniciaVideo,
}) {
  return (
    <Card
      style={{
        padding: 10,
      }}
    >
      <CardContent
        style={{
          padding: 0,
          flexDirection: "column",
          display: "flex",
          height: 300,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          style={{
            height: 50,
            marginRight: 10,
            backgroundColor: "#e27e07",
            color: "white",
            fontSize: 30,
            marginBlock: 30,
          }}
          onClick={() => {
            setIniciaFormulario(true);
            setIniciaVideo(false);
          }}
        >
          Iniciar Formulario
        </Button>
        <Button
          variant="contained"
          style={{
            height: 50,
            marginRight: 10,
            backgroundColor: "#e27e07",
            color: "white",
            fontSize: 30,
            marginBlock: 30,
          }}
          onClick={() => {
            setIniciaFormulario(false);
            setIniciaVideo(true);
          }}
        >
          Iniciar Video
        </Button>
      </CardContent>
    </Card>
  );
}
