import { Typography } from "@material-ui/core";
import React from "react";

const CountDownTimer = ({
  hoursMinSecs,
  onFinish = () => {},
  loop = false,
}) => {
  const { hours = 0, minutes = 0, seconds = 60 } = hoursMinSecs;
  const [finaliza, setFinaliza] = React.useState(false);
  const [[hrs, mins, secs], setTime] = React.useState([
    hours,
    minutes,
    seconds,
  ]);

  const tick = () => {
    if (hrs === 0 && mins === 0 && secs === 0) reset();
    else if (mins === 0 && secs === 0) {
      setTime([hrs - 1, 59, 59]);
    } else if (secs === 0) {
      setTime([hrs, mins - 1, 59]);
    } else {
      setTime([hrs, mins, secs - 1]);
    }
  };

  const reset = () => {
    if (loop) {
      setTime([parseInt(hours), parseInt(minutes), parseInt(seconds)]);
    } else {
      setFinaliza(true);
    }
    onFinish();
  };

  React.useEffect(() => {
    if (!finaliza) {
      const timerId = setInterval(() => tick(), 1000);
      return () => clearInterval(timerId);
    }
  });

  return (
    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
      {`Tiempo Restante ${mins.toString().padStart(2, "0")}:${secs
        .toString()
        .padStart(2, "0")}`}
    </Typography>
  );
};

export default CountDownTimer;
