import {
  AppBar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CutomModal from "../pasos/CutomModal";
import CountDownTimer from "../utiles/CountDownTimer";
import PreguntaWonderlic from "./PreguntaWonderlic";

const URL_POSTULANTE = process.env.REACT_APP_URL_BASE_API_POSTULANTE;

export default function Wonderlic() {
  const [uuIdTest] = React.useState(
    new URLSearchParams(window.location.search).get("uuIdTest")
  );
  const [preguntas, setPreguntas] = useState();
  const [aceptaCondiciones, setAceptaCondiciones] = useState(false);
  const [mostrarModal, setMostrarModal] = React.useState(false);
  const [tokenValido, setTokenValido] = React.useState(false);
  const [datosModal, setDatosModal] = React.useState({
    closeModal: false,
    subtitulo: null,
    texto: null,
  });

  const obtienePreguntas = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const fetchResponse = await fetch(
        URL_POSTULANTE + "test/preguntas/1",
        requestOptions
      );
      const data = await fetchResponse.json();
      if (data.codigoRespuesta === 200) {
        setPreguntas(data.entidad);
        setAceptaCondiciones(true);
      }
    } catch (e) {
      return e;
    }
  };

  const validaToken = async () => {
    try {
      const fetchResponse = await fetch(
        URL_POSTULANTE + "test/validatoken/" + uuIdTest
      );
      const data = await fetchResponse.json();
      if (data.codeResponse !== 200) {
        setDatosModal({
          closeModal: false,
          subtitulo: "Test WONDERLIC",
          texto: "El id de test ingresado no es valido",
        });
        setMostrarModal(true);
      } else {
        setTokenValido(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const iniciarTest = async () => {
    try {
      const fetchResponse = await fetch(
        URL_POSTULANTE + "test/iniciar/" + uuIdTest
      );
      const data = await fetchResponse.json();
      if (data.codeResponse !== 200) {
        setDatosModal({
          closeModal: true,
          subtitulo: "Test WONDERLIC",
          texto: "Se produjo un error no se puede iniciar el TEST.",
        });
        setMostrarModal(true);
      } else {
        if (preguntas === undefined) {
          obtienePreguntas();
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const finalizarTest = async (acaboElTiempo = false) => {
    try {
      const fetchResponse = await fetch(
        URL_POSTULANTE + "test/finalizar/" + uuIdTest
      );
      const data = await fetchResponse.json();
      if (data.codeResponse !== 200) {
        setDatosModal({
          closeModal: true,
          subtitulo: "Test WONDERLIC",
          texto: "Se produjo un error no se puede finalizar el TEST.",
        });
      } else {
        let mensaje = "Gracias por participar en el proceso.";
        if (acaboElTiempo) {
          mensaje =
            "Se ha acabado el tiempo asignado para responder el TEST. " +
            mensaje;
        }
        setDatosModal({
          closeModal: false,
          subtitulo: "Test WONDERLIC",
          texto: mensaje,
        });
      }
      setMostrarModal(true);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (uuIdTest == null) {
      setDatosModal({
        closeModal: false,
        subtitulo: "Test WONDERLIC",
        texto: "Debe ingresar un ID de Test para continuar",
      });
      setMostrarModal(true);
    } else {
      if (!tokenValido) {
        validaToken();
      }
    }
  }, [uuIdTest, tokenValido]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={{ flexGrow: 1 }}>
      {!aceptaCondiciones && (
        <Card style={{ margin: 30 }}>
          <CardContent>
            <Typography
              variant="h3"
              gutterBottom
              component="div"
              style={{
                textAlign: "center",
                textDecoration: "underline",
                marginBlock: 30,
              }}
            >
              INSTRUCCIONES
            </Typography>
            <Typography variant="body1" gutterBottom>
              LEA ESTA PÁGINA CUIDADOSAMENTE Y SIGA LAS INSTRUCCIONES
              EXACTAMENTE. LOS PROBLEMAS DEBEN RESOLVERSE SIN LA AYUDA DE
              CALCULADORAS U OTROS DISPOSITIVOS QUE FACILITEN LA SOLUCIÓN DE
              PROBLEMAS.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Esta es una prueba de su capacidad para resolver problemas. La
              prueba se compone de preguntas de varios tipos. A continuación
              mostraremos un ejemplo de preguntas y su correspondiente
              respuesta:
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ fontWeight: "bold", marginTop: 30 }}
            >
              Ejemplo Nº 1
            </Typography>
            <Typography variant="body1" gutterBottom>
              COSECHAR es lo opuesto de:
            </Typography>
            <Typography variant="body1" gutterBottom>
              1) Obtener 2) Alentar 3) Continuar 4) Existir 5) Sembrar
            </Typography>
            <Typography variant="body1" gutterBottom>
              La respuesta correcta es “sembrar”. La palabra correcta tiene el
              numero 5). Por lo tanto, el número 5 se escribe en la hora de
              respuesta en el casillero Nº 1 de los ejemplos.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ fontWeight: "bold" }}
            >
              Ejemplo Nº 2
            </Typography>
            <Typography variant="body1" gutterBottom>
              Responda usted mismo la siguiente pregunta:
            </Typography>
            <Typography variant="body1" gutterBottom>
              El papel se vende a 23 Centavos el cuaderno. ¿Cuánto costarán 4
              cuadernos?
            </Typography>
            <Typography variant="body1" gutterBottom>
              La respuesta correcta es 92 Centavos. Debe escribirse “92” en la
              hoja de respuesta en el casillero Nº 2 de los ejemplos.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ fontWeight: "bold" }}
            >
              Ejemplo Nº 3
            </Typography>
            <Typography variant="body1" gutterBottom>
              CASA/CAZA ¿Cuál es la relación entre el significado de estas
              palabras?
            </Typography>
            <Typography variant="body1" gutterBottom>
              1) Similar 2) Opuesto 3) Ni similar ni opuesto
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ marginBottom: 30 }}
            >
              La respuesta correcta es “ni similar ni opuesto”, que es la
              respuesta Nº 3; todo lo que usted debe hacer es escribir el número
              “3” en la hoja de respuesta en el casillero Nº 3 de los ejemplos.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Esta prueba se compone de 50 preguntas. Probablemente usted no
              tendrá tiempo de responder a todas ellas, pero aun así debe
              intentarlo. Después de que el examinador le indique que puede
              comenzar, tendrá exactamente 12 minutos para responder a tantas
              preguntas como pueda. No se apresure, porque podrá cometer errores
              y es necesario obtener la máxima cantidad de respuestas correctas.
              Las preguntas serán cada vez más difíciles y le recomendamos que
              las conteste en el orden en que aparecen. No se demore demasiado
              en un problema. Una vez que la prueba comience, el examinador no
              responderá a ninguna consulta.
            </Typography>
          </CardContent>
          <CardActions style={{ justifyContent: "end" }}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#f47c06", color: "#ffffff" }}
              onClick={() => iniciarTest()}
            >
              Iniciar TEST
            </Button>
          </CardActions>
        </Card>
      )}
      {aceptaCondiciones && (
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="fixed" style={{ backgroundColor: "#0a5dc1" }}>
            <Toolbar style={{ justifyContent: "space-between" }}>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Test WONDERLIC
              </Typography>
              <CountDownTimer
                onFinish={() => finalizarTest(true)}
                hoursMinSecs={{ hours: 0, minutes: 12, seconds: 0 }}
              />
            </Toolbar>
          </AppBar>
          <div style={{ marginTop: 120 }}>
            {preguntas !== undefined &&
              preguntas.map((pregunta, index) => {
                pregunta["listaJSON"] = JSON.parse(pregunta.lista);
                return (
                  <PreguntaWonderlic
                    key={`pregunta_wonderlic_${index}`}
                    pregunta={pregunta}
                    uuidTest={uuIdTest}
                  />
                );
              })}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <div style={{ textAlign: "end", width: 800 }}>
              <Button
                variant="contained"
                style={{ backgroundColor: "#f47c06", color: "#ffffff" }}
                onClick={() => finalizarTest()}
              >
                Finalizar TEST
              </Button>
            </div>
          </div>
        </Box>
      )}
      <CutomModal
        setMostrarModal={setMostrarModal}
        open={mostrarModal}
        closeModal={datosModal.closeModal}
        subtitulo={datosModal.subtitulo}
        texto={datosModal.texto}
      />
    </Box>
  );
}
