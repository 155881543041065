import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  IconButton,
  makeStyles,
  TableCell,
  TableRow,
} from "@material-ui/core";
import CustomSelectInput from "../inputs/CustomSelectInput";
import CloseIcon from "@material-ui/icons/Close";
import CustomTextInput from "../inputs/CustomTextInput";
import CustomRadioInput from "../inputs/CustomRadioInput";
import CustomUploadFile from "../inputs/CustomUploadFile";
import FormInputDynamic from "../formularios/FormInputDynamic";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  title: {
    fontSize: 20,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formInput: {
    marginBottom: 20,
  },
  button: {
    backgroundColor: "#e27e07",
    color: "white",
    marginBottom: 20,
  },
}));

export default function DatosExperienciaLaboral(props) {
  const classes = useStyles();

  const [experiences, setExperiences] = useState([]);
  const [archivos, setArchivos] = useState([]);
  const [actualizar, setActualizar] = useState(false);
  const [errorSinArchivo, setErrorSinArchivo] = useState(false);
  const [validar, setValidar] = useState(false);
  const [tieneCurriculum, setTieneCurriculum] = useState(null);

  const addElement = () => {
    setValidar(false);
    setActualizar(!actualizar);
    setExperiences((elements) => [
      ...elements,
      {
        nombreEmpresa: null,
        periodoIngreso: null,
        periodoEgreso: null,
        rubro: null,
        nombreCargo: null,
        rol: null,
        funciones: null,
        deshabilita: false,
      },
    ]);
  };
  const addArchivo = () => {
    setValidar(false);
    setActualizar(!actualizar);
    setArchivos((elements) => [...elements, undefined]);
  };

  const removeElement = (index) => {
    setValidar(false);
    if (
      props.formulario()["experienciaLaboral"] !== undefined &&
      props.formulario()["experienciaLaboral"].length > 0
    ) {
      eliminaElemento(
        props.formulario()["experienciaLaboral"],
        index,
        (listaAux) => {
          props.formulario()["experienciaLaboral"] = listaAux;
        }
      );
    }
    eliminaElemento(experiences, index, setExperiences);
    setActualizar(!actualizar);
  };

  const removeArchivo = (index) => {
    setValidar(false);
    let archivosAux = [];
    for (let pos = 0; pos < archivos.length; pos++) {
      if (parseInt(pos) !== parseInt(index)) {
        let file = archivos[pos];
        if (file !== null && file !== undefined) {
          let fileAux =
            file.file !== null
              ? new File([file.file], file.file.name, { type: file.type })
              : null;
          archivosAux.push({
            idDocumento: file.idDocumento,
            name: file.name,
            file: fileAux,
          });
        } else {
          archivosAux.push(null);
        }
      }
    }
    if (tieneCamposRequeridos(3) && archivosAux.length === 0) {
      props.setModal({
        closeModal: true,
        subtitulo: "Ingreso Proceso Postulante",
        texto: "Debe ingresar al menos un archivo.",
      });
    }
    setArchivos(archivosAux);
    props.uploadFile(archivosAux);
    setActualizar(!actualizar);
  };

  const eliminaElemento = (lista, index, setLista) => {
    let listaAux = [];
    let pos;
    for (pos in lista) {
      if (parseInt(pos) !== parseInt(index)) {
        listaAux.push(JSON.parse(JSON.stringify(lista[pos])));
      }
    }
    if (tieneCamposRequeridos(2) && listaAux.length === 0) {
      props.setModal({
        closeModal: true,
        subtitulo: "Ingreso Proceso Postulante",
        texto: "Debe ingresar al menos una experiencia.",
      });
    }
    setLista(listaAux);
  };

  const onAddAtribute = (index, llave, valor) => {
    if (props.formulario()["experienciaLaboral"] === undefined) {
      props.onDataChange("experienciaLaboral", []);
    }
    var experiencia = props.formulario()["experienciaLaboral"][index];
    if (experiencia === undefined) {
      experiencia = {};
    }
    experiencia[llave] = valor;
    props.formulario()["experienciaLaboral"][index] = experiencia;
    experiences[index][llave] = valor;
  };

  const deshabiltaCampo = (index) => {
    experiences[index].deshabilita = !experiences[index].deshabilita;
    setActualizar(!actualizar);
  };

  const validaFormulario = () => {
    var requerido = true;
    if (experiences !== undefined) {
      if (experiences.length > 0) {
        for (const index in experiences) {
          let parametros = experiences[index];
          if (Object.entries(parametros).length === 0) {
            requerido = false;
          } else {
            for (const key in Object.entries(parametros)) {
              const element = Object.entries(parametros)[key];
              let pregunta = props.preguntas.find((p) => {
                return p.name === element[0];
              });
              if (
                requerido &&
                pregunta !== undefined &&
                pregunta["requerido"] === 1 &&
                (element[1] === undefined ||
                  element[1] === null ||
                  element[1].length === 0)
              ) {
                requerido = false;
                break;
              }
            }
          }
        }
      }
    }
    if (archivos.length > 0) {
      for (const index in archivos) {
        if (archivos[index] === undefined) {
          archivos[index] = null;
          requerido = false;
        }
      }
    }
    return requerido;
  };

  const siguiente = () => {
    if (
      (props.file !== undefined ||
        tieneCurriculum ||
        !props.archivorequerido) &&
      validaFormulario()
    ) {
      if (props.enviarFormulario !== undefined) {
        props.enviarFormulario();
      } else {
        props.nextStep();
      }
    } else {
      if (!validaFormulario()) {
        props.setModal({
          closeModal: true,
          subtitulo: "Ingreso Proceso Postulante",
          texto: "Completar todos los campos obligatorios antes de avanzar",
        });
        setValidar(true);
      }
      if (props.file === undefined && props.archivorequerido) {
        setErrorSinArchivo(true);
      }
      setActualizar(!actualizar);
    }
  };

  const handlerUploadFile = (file, index) => {
    archivos[index] = {
      idDocumento: null,
      name: file.name,
      file: file,
    };
    props.uploadFile(archivos);
    setActualizar(!actualizar);
  };

  const tieneCamposRequeridos = (subGrupo) => {
    let preguntas = tienePreguntas(subGrupo);
    let tieneRequerido = preguntas.find((pregunta) => pregunta.requerido === 1);
    if (tieneRequerido !== undefined) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (tieneCamposRequeridos(2) && experiences.length === 0) {
      addElement();
    }
  }, [experiences.length]);

  useEffect(() => {
    if (tieneCamposRequeridos(3) && archivos.length === 0) {
      addArchivo();
    }
  }, [archivos.length]);

  useEffect(() => {
    let experienciasLst = props.formulario()["experienciaLaboral"];
    if (experienciasLst !== undefined && experienciasLst.length > 0) {
      experienciasLst.forEach((element) => {
        if (element.periodoEgreso === null) {
          element["deshabilita"] = true;
        } else {
          element["deshabilita"] = false;
        }
      });
      setExperiences(JSON.parse(JSON.stringify(experienciasLst)));
    }
  }, [props.formulario()["experienciaLaboral"]?.length]);

  useEffect(() => {
    setTieneCurriculum(
      tienePreguntas(3).length === 0
        ? true
        : !(tienePreguntas(3)[0].requerido === 1)
    );
    if (props.formulario()["rut"] === undefined) {
      props.firstStep();
    } else {
      /*if (
        experiences.length === 0 &&
        props.formulario()["experienciaLaboral"] !== undefined &&
        props.formulario()["experienciaLaboral"].length > 0
      ) {
        props.formulario()["experienciaLaboral"].forEach((element) => {
          if (element.periodoEgreso === null) {
            element["deshabilita"] = true;
          } else {
            element["deshabilita"] = false;
          }
        });
        setExperiences(props.formulario()["experienciaLaboral"]);
        setActualizar(!actualizar);
      }*/
      if (
        archivos.length === 0 &&
        props.formulario()["archivos"] !== undefined &&
        props.formulario()["archivos"] !== null &&
        props.formulario()["archivos"].length > 0
      ) {
        if (
          props.formulario()["tieneCurriculum"] !== undefined &&
          props.formulario()["tieneCurriculum"] !== null
        ) {
          setTieneCurriculum(props.formulario()["tieneCurriculum"]);
          setArchivos(props.formulario()["archivos"]);
          props.uploadFile(props.formulario()["archivos"]);
        } else {
          setTieneCurriculum(
            tienePreguntas(3).length === 0
              ? true
              : !(tienePreguntas(3)[0].requerido === 1)
          );
        }
        setActualizar(!actualizar);
      }
    }
  }, [actualizar, props.formulario]);

  const tienePreguntas = (subGrupo) => {
    let preguntas = props.preguntas.filter(
      (pregunta) => pregunta.subGrupo === subGrupo && pregunta.activo === 1
    );
    return preguntas !== undefined ? preguntas : [];
  };

  const Bloque = ({
    title,
    children,
    mostrarCampoObligatorios = true,
    agregar = () => {},
  }) => {
    return (
      <div
        style={{
          borderWidth: 1,
          borderColor: "#888888",
          borderStyle: "solid",
          padding: 10,
          borderRadius: 15,
          marginBottom: 25,
        }}
      >
        <TableRow style={{ justifyContent: "space-between", display: "flex" }}>
          <TableCell style={{ borderBlockWidth: 0 }}>
            <CardHeader
              title={title}
              subheader={
                mostrarCampoObligatorios ? (
                  <div style={{ marginBottom: 10, fontStyle: "italic" }}>
                    Los campos (<span style={{ color: "#e94949" }}>*</span>) son
                    obligatorios.
                  </div>
                ) : (
                  ""
                )
              }
            />
          </TableCell>
          <TableCell style={{ borderBlockWidth: 0 }}>
            <Button
              variant="contained"
              onClick={agregar}
              className={classes.button}
            >
              Agregar
            </Button>
          </TableCell>
        </TableRow>
        {children}
      </div>
    );
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        {(tienePreguntas(1).length > 0 || tienePreguntas(2).length > 0) && (
          <Bloque
            title="Experiencia Laboral"
            mostrarCampoObligatorios={experiences.length > 0}
            agregar={addElement}
          >
            {tienePreguntas(1).length > 0 && (
              <CustomRadioInput
                {...props}
                label={"¿Tiene experiencia Laboral?"}
                requiered={tienePreguntas(1)[0].requiered}
                validar={validar}
                className={classes.formInput}
                name="experienciaLaboralRemunerada"
                listIn={[
                  { label: "Con remuneración", value: "si" },
                  { label: "Sin remuneración", value: "no" },
                ]}
                valueIn={props.formulario()["experienciaLaboralRemunerada"]}
              />
            )}
            {experiences.map((item, index) => {
              return (
                <div key={"experiencia-" + index}>
                  <CardHeader
                    title={"N°" + (index + 1)}
                    action={
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onClick={() => deshabiltaCampo(index)}
                              checked={item.deshabilita}
                            />
                          }
                          label="Trabajo actual"
                          labelPlacement="start"
                        />
                        <IconButton
                          aria-label="settings"
                          onClick={() => removeElement(index)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    }
                  />
                  {props.preguntas !== undefined &&
                    props.preguntas.length > 0 &&
                    props.preguntas.map((pregunta, indexP) => {
                      if (
                        pregunta.name !== "curriculum" &&
                        pregunta.subGrupo === 2
                      ) {
                        return (
                          <FormInputDynamic
                            key={`pregunta-${indexP}`}
                            activo={pregunta.activo === 0 ? false : true}
                            requiered={
                              pregunta.requerido === 0 ? "false" : "true"
                            }
                            validar={validar}
                            className={classes.formInput}
                            type={pregunta.tipoInput.descripcion}
                            label={pregunta.label}
                            name={pregunta.name}
                            index={index}
                            rows={pregunta.name === "funciones" ? 5 : 1}
                            helperText={pregunta.helperText}
                            valueType={pregunta.valueType}
                            multiple={pregunta.multiple}
                            maxLength={pregunta.maxLength}
                            url={pregunta.url}
                            multiline={
                              pregunta.name === "funciones" ? true : false
                            }
                            value={
                              item !== undefined
                                ? item[pregunta.name]?.toString()
                                : ""
                            }
                            onDataChange={onAddAtribute}
                          />
                        );
                      }
                    })}
                </div>
              );
            })}
          </Bloque>
        )}

        {tienePreguntas(3).length > 0 && (
          <Bloque
            title="Cargar Currículum Vitae (formatos PDF, WORD o Texto) "
            mostrarCampoObligatorios={tienePreguntas(3)[0].requerido === 1}
            agregar={addArchivo}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              {archivos &&
                archivos.map((archivo, index) => {
                  let esRequerido = tienePreguntas(3)[0].requerido === 1;
                  return (
                    <CustomUploadFile
                      archivo={archivo}
                      uploadFile={(file) => handlerUploadFile(file, index)}
                      removeElement={() => removeArchivo(index)}
                      requiered={esRequerido}
                      error={archivos[index] === null}
                    />
                  );
                })}
            </div>
          </Bloque>
        )}
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          style={{ backgroundColor: "#f6a623", color: "#ffffff" }}
          onClick={siguiente}
        >
          {props.enviarFormulario !== undefined ? "Enviar" : "Continuar"}
        </Button>
        <Button variant="contained" onClick={props.previousStep}>
          Anterior
        </Button>
      </CardActions>
    </Card>
  );
}
