const preguntas = [
  { numero: 1, glosa: "PLANIFICO MIS TAREAS CON CUIDADO", tipo: "desc" },
  { numero: 2, glosa: "HAGO LAS COSAS SIN PENSARLAS", tipo: "asc" },
  { numero: 3, glosa: "CASI NUNCA ME TOMO LAS COSAS A PECHO", tipo: "asc" },
  {
    numero: 4,
    glosa: "MIS PENSAMIENTOS PUEDEN TENER GRAN VELOCIDAD",
    tipo: "asc",
  },
  { numero: 5, glosa: "PLANIFICO MIS VIAJES CON ANTELACION", tipo: "desc" },
  { numero: 6, glosa: "SOY UNA PERSONA CON AUTOCONTROL", tipo: "desc" },
  { numero: 7, glosa: "ME CONCENTRO CON FACILIDAD", tipo: "desc" },
  { numero: 8, glosa: "AHORRO CON REGULARIDAD", tipo: "desc" },
  {
    numero: 9,
    glosa: "SE ME HACE DIFICIL ESTAR QUIETO DURANTE LARGOS PERIODOS DE TIEMPO",
    tipo: "desc",
  },
  { numero: 10, glosa: "PIENSO LAS COSAS CUIDADOSAMENTE", tipo: "desc" },
  { numero: 11, glosa: "PLANIFICO PARA TENER UN TRABAJO FINO", tipo: "desc" },
  {
    numero: 12,
    glosa: "DIGO LAS COSAS SIN PENSARLAS",
    tipo: "asc",
  },
  {
    numero: 13,
    glosa: "ME GUSTA PENSAR SOBRE PROBLEMAS COMPLICADOS",
    tipo: "desc",
  },
  { numero: 14, glosa: "CAMBIO DE TRABAJO FRECUENTEMENTE", tipo: "asc" },
  { numero: 15, glosa: "ACTUO IMPULSIVAMENTE", tipo: "asc" },
  {
    numero: 16,
    glosa: "ME ABURRO CON FACILIDAD TRATANDO DE RESOLVER PROBLEMAS EN MI MENTE",
    tipo: "asc",
  },
  {
    numero: 17,
    glosa: "VISITO AL MEDICO Y AL DENTISTA CON REGULARIDAD",
    tipo: "desc",
  },
  {
    numero: 18,
    glosa: "HAGO LAS COSAS EN EL MOMENTO QUE SE ME OCURREN",
    tipo: "asc",
  },
  {
    numero: 19,
    glosa: "SOY UNA PERSONA QUE PIENSA SIN DISTRAERSE",
    tipo: "desc",
  },
  { numero: 20, glosa: "CAMBIO DE VIVIENDA A MENUDO", tipo: "asc" },
  { numero: 21, glosa: "COMPRO COSAS IMPULSIVAMENTE", tipo: "asc" },
  { numero: 22, glosa: "TERMINO LO QUE QUIERO", tipo: "desc" },
  { numero: 23, glosa: "CAMINO Y ME MUEVO CON RAPIDEZ", tipo: "asc" },
  { numero: 24, glosa: "RESUELVO LOS PROBLEMAS EXPERIMENTANDO", tipo: "asc" },
  {
    numero: 25,
    glosa: "GASTO EN EFECTIVO O A CREDITO MAS DE LO QUE GANO",
    tipo: "asc",
  },
  { numero: 26, glosa: "HABLO RAPIDO", tipo: "asc" },
  {
    numero: 27,
    glosa: "TENGO PENSAMIENTOS EXTRAÑOS CUANDO ESTOY PENSANDO",
    tipo: "asc",
  },
  {
    numero: 28,
    glosa: "ME INTERESA MAS EL PRESENTE QUE EL FUTURO",
    tipo: "asc",
  },
  { numero: 29, glosa: "ME SIENTO INQUIETO EN CLASES O CHARLAS", tipo: "asc" },
  { numero: 30, glosa: "PLANIFICO EL FUTURO", tipo: "desc" },
];

export default preguntas;
