import { Button, IconButton, Typography } from "@material-ui/core";
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";

export default function CustomUploadFile({
  uploadFile,
  archivo,
  requiered,
  removeElement,
  error,
}) {
  //const [nombre, setNombre] = React.useState(null);

  const handleChange = (event) => {
    uploadFile(event.target.files[0]);
    //setNombre(event.target.files[0].name);
  };

  //const [error, setError] = useState(false);

  React.useEffect(() => {}, [error, archivo]);

  return (
    <div style={{ display: "flex", flexDirection: "row", paddingLeft: 20 }}>
      <IconButton
        aria-label="settings"
        onClick={() => removeElement()}
        style={{
          backgroundColor: "#e27e07",
          alignSelf: "baseline",
          marginRight: 10,
          marginTop: 8,
          color: "#fff",
        }}
        size={"small"}
      >
        <CloseIcon />
      </IconButton>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Button
          variant="contained"
          component="label"
          style={
            error
              ? { color: "#f44336", backgroundColor: "#f7aeae", marginBlock: 5 }
              : { color: "#fff", backgroundColor: "#0a5dc1", marginBlock: 5 }
          }
        >
          {archivo !== undefined && archivo !== null
            ? archivo.name
            : "Seleccionar archivo"}
          <input type="file" hidden onChange={handleChange} required={true} />
        </Button>
        {error && (
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            style={{ color: "#f44336" }}
          >
            Debe ingresar un documento.
          </Typography>
        )}
      </div>
      {requiered && (
        <Typography
          variant="caption"
          display="block"
          gutterBottom
          style={{
            color: "#f44336",
            fontSize: 23,
            fontWeight: "bold",
            marginLeft: 10,
          }}
        >
          *
        </Typography>
      )}
    </div>
  );
}
